import { styled } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import React, { forwardRef } from 'react';

export interface TableProps extends React.ComponentProps<'table'> {
  sx?: SxProps<Theme>;
}

const TableRoot = styled('table')({
  width: '100%',
  tableLayout: 'fixed',
  borderCollapse: 'collapse',
  borderSpacing: 0,
});

const Table = forwardRef<React.ElementRef<'table'>, TableProps>(({ children, ...rest }, ref) => (
  <TableRoot ref={ref} {...rest}>
    {children}
  </TableRoot>
));

export default Table;
