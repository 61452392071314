import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const LockOpenIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="28" height="28" viewBox="0 0 28 28" {...props}>
    <path d="M6.66935 10.1714H17.5202V7.42857C17.5202 6.4 17.1677 5.52381 16.4629 4.8C15.7581 4.07619 14.9048 3.71429 13.9032 3.71429C12.9016 3.71429 12.0484 4.07619 11.3435 4.8C10.6387 5.52381 10.2863 6.4 10.2863 7.42857H8.61694C8.61694 5.92381 9.13165 4.64286 10.1611 3.58571C11.1905 2.52857 12.4379 2 13.9032 2C15.3685 2 16.6159 2.52857 17.6454 3.58571C18.6748 4.64286 19.1895 5.92381 19.1895 7.42857V10.1714H21.1371C21.6008 10.1714 21.995 10.3381 22.3196 10.6714C22.6442 11.0048 22.8065 11.4095 22.8065 11.8857V24.2857C22.8065 24.7619 22.6442 25.1667 22.3196 25.5C21.995 25.8333 21.6008 26 21.1371 26H6.66935C6.20565 26 5.81149 25.8333 5.4869 25.5C5.1623 25.1667 5 24.7619 5 24.2857V11.8857C5 11.4095 5.1623 11.0048 5.4869 10.6714C5.81149 10.3381 6.20565 10.1714 6.66935 10.1714ZM6.66935 24.2857H21.1371V11.8857H6.66935V24.2857ZM13.9032 20.2857C14.4968 20.2857 15.0022 20.0762 15.4196 19.6571C15.8369 19.2381 16.0456 18.7333 16.0456 18.1429C16.0456 17.5714 15.8369 17.0524 15.4196 16.5857C15.0022 16.119 14.4968 15.8857 13.9032 15.8857C13.3097 15.8857 12.8042 16.119 12.3869 16.5857C11.9696 17.0524 11.7609 17.5714 11.7609 18.1429C11.7609 18.7333 11.9696 19.2381 12.3869 19.6571C12.8042 20.0762 13.3097 20.2857 13.9032 20.2857ZM6.66935 24.2857V11.8857V24.2857Z" />
  </SvgIcon>
);

export default LockOpenIcon;
