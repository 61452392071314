import { styled, SxProps, Theme } from '@mui/material/styles';
import React from 'react';

export interface IconWrapperProps {
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
}

const IconWrapperRoot = styled('span')({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const IconWrapper: React.FC<IconWrapperProps> = ({ children, ...rest }) => (
  <IconWrapperRoot {...rest}>{children}</IconWrapperRoot>
);

export default IconWrapper;
