import { Button as BaseButton, buttonClasses, ButtonProps as BaseButtonProps } from '@mui/base';
import { styled } from '@mui/material/styles';
import React from 'react';

export interface ButtonBaseProps extends BaseButtonProps {}

const ButtonBaseRoot = styled(BaseButton)({
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  boxSizing: 'border-box',

  // Reset appearance
  MozAppearance: 'none',
  WebkitAppearance: 'none',

  // Reset margin, padding, and border
  margin: 0,
  padding: 0,
  border: 0,
  borderRadius: 0,

  // Appearance and interaction
  outline: 0,
  cursor: 'pointer',
  userSelect: 'none',
  verticalAlign: 'middle',
  transition: 'opacity 0.15s',
  willChange: 'opacity',

  // Color and background
  backgroundColor: 'transparent',
  color: 'inherit',
  WebkitTapHighlightColor: 'transparent',

  // Text styling
  textAlign: 'center',
  textDecoration: 'none',

  // Provide subtle visual feedback for the user on hover
  '&:hover': {
    opacity: 0.85,
  },

  [`&.${buttonClasses.active}`]: {
    opacity: 1,
  },

  [`&.${buttonClasses.disabled}`]: {
    pointerEvents: 'none', // Disable link interactions
    cursor: 'default',
  },
});

const ButtonBase: React.FC<ButtonBaseProps> = (props) => <ButtonBaseRoot {...props} />;

export default ButtonBase;
