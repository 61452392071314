import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const CheckIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="34" height="34" viewBox="0 0 34 34" {...props}>
    <path d="M14.6221 26.479L4.63276 19.2614L6.08992 17.2669L14.0771 23.0379L26.5678 5.94076L28.5699 7.38735L14.6221 26.479Z" />
  </SvgIcon>
);

export default CheckIcon;
