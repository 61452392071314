import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const SadFaceIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M22.2 12C22.2 17.6333 17.6333 22.2 12 22.2C6.3667 22.2 1.8 17.6333 1.8 12C1.8 6.3667 6.3667 1.8 12 1.8C17.6333 1.8 22.2 6.3667 22.2 12ZM24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM6.46154 14.7923C5.96448 14.7923 5.56154 15.1952 5.56154 15.6923C5.56154 16.1894 5.96448 16.5923 6.46154 16.5923H17.5385C18.0355 16.5923 18.4385 16.1894 18.4385 15.6923C18.4385 15.1952 18.0355 14.7923 17.5385 14.7923H6.46154ZM10.1538 8.76923C10.1538 9.53393 9.53392 10.1538 8.76922 10.1538C8.00452 10.1538 7.38461 9.53393 7.38461 8.76923C7.38461 8.00453 8.00452 7.38462 8.76922 7.38462C9.53392 7.38462 10.1538 8.00453 10.1538 8.76923ZM15.2308 10.1538C15.9955 10.1538 16.6154 9.53393 16.6154 8.76923C16.6154 8.00453 15.9955 7.38462 15.2308 7.38462C14.4661 7.38462 13.8462 8.00453 13.8462 8.76923C13.8462 9.53393 14.4661 10.1538 15.2308 10.1538Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </SvgIcon>
);

export default SadFaceIcon;
