import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const ResetIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="34" height="34" viewBox="0 0 34 34" {...props}>
    <path d="M16.9801 4.11718C9.64551 4.12714 3.71875 10.0605 3.71875 17.3951C3.71875 21.6451 5.71758 25.4303 8.82539 27.8607L7.58027 29.4545C7.44414 29.6305 7.57031 29.8861 7.78945 29.8828L13.3344 29.8562C13.507 29.8562 13.6332 29.6935 13.59 29.5275L12.2785 24.1387C12.2667 24.0901 12.2414 24.0458 12.2055 24.011C12.1696 23.9762 12.1246 23.9522 12.0757 23.9418C12.0268 23.9314 11.976 23.935 11.929 23.9522C11.8821 23.9694 11.8409 23.9995 11.8104 24.0391L10.459 25.7689C10.1203 25.5033 9.79492 25.2144 9.48613 24.9057C8.51534 23.9382 7.74176 22.7914 7.2084 21.5289C6.65391 20.2207 6.375 18.8295 6.375 17.3951C6.375 15.9607 6.65391 14.5695 7.2084 13.2613C7.74297 11.9963 8.50996 10.8607 9.48613 9.88456C10.4623 8.90839 11.5979 8.1414 12.8629 7.60683C14.1744 7.05233 15.5656 6.77343 17 6.77343C18.4344 6.77343 19.8256 7.05233 21.1338 7.60683C22.3988 8.1414 23.5344 8.90839 24.5105 9.88456C25.4867 10.8607 26.2537 11.9963 26.7883 13.2613C27.3428 14.5695 27.6217 15.9607 27.6217 17.3951C27.6217 18.8295 27.3428 20.2207 26.7883 21.5289C26.2549 22.7914 25.4813 23.9382 24.5105 24.9057C24.2615 25.1547 24.0025 25.3871 23.7336 25.6096C23.6797 25.6533 23.6453 25.7166 23.6378 25.7856C23.6304 25.8547 23.6505 25.9238 23.6937 25.9781L25.002 27.6549C25.0949 27.7711 25.2643 27.791 25.3805 27.698C28.3721 25.2609 30.2812 21.5521 30.2812 17.3951C30.2812 10.0539 24.3246 4.10722 16.9801 4.11718Z" />
  </SvgIcon>
);

export default ResetIcon;
