import * as T from '@aily/graphql-sdk/schema';
import { Stack, Typography } from '@mui/material';
import React, { FC } from 'react';

import { FitText } from '../FitText';

export interface TableCellTextResultProps {
  value: T.TextResult;
}

export const TableCellTextResult: FC<TableCellTextResultProps> = ({ value }) => {
  const { isHighlighted, value: label, subLabels } = value;
  if (!label) return null;

  if (subLabels?.length) {
    return (
      <Stack spacing={0.5}>
        <Typography variant="body" component="span">
          {label}
        </Typography>
        <Typography variant="small" component="span" color="text.secondary">
          {subLabels[0]}
        </Typography>
        {!!subLabels[1] && (
          <Typography variant="xSmall" component="span" color="text.secondary">
            {subLabels[1]}
          </Typography>
        )}
      </Stack>
    );
  }

  if (isHighlighted) {
    return (
      <Typography variant="h9" component="span" data-testid="TextCell">
        {label}
      </Typography>
    );
  }

  return <FitText data-testid="TextCell">{label}</FitText>;
};

export default TableCellTextResult;
