import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const WifiOffIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M21.8182 12.3927L24 10.2109C19.9309 6.14182 14.3236 4.59273 9.05455 5.5091L11.8691 8.32364C15.4691 8.30182 19.08 9.65455 21.8182 12.3927ZM19.6364 14.5746C18.4582 13.3964 17.0618 12.5564 15.5782 12.0327L18.8727 15.3273L19.6364 14.5746ZM8.72727 18.9382L12 22.2109L15.2727 18.9382C13.4727 17.1273 10.5382 17.1273 8.72727 18.9382ZM2.62909 2.18182L1.09091 3.72001L4.41818 7.04728C2.82545 7.84364 1.33091 8.89091 0 10.2109L2.18182 12.3927C3.52364 11.0509 5.07273 10.0364 6.73091 9.36001L9.17455 11.8036C7.40727 12.2727 5.74909 13.2 4.36364 14.5746L6.54545 16.7564C8.01818 15.2836 9.93818 14.5309 11.88 14.5091L19.6036 22.2327L21.1418 20.6946L2.62909 2.18182Z" />
  </SvgIcon>
);

export default WifiOffIcon;
