import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const AlertIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M12 2L1 21H23M12 6L19.53 19H4.47M11 10V14H13V10M11 16V18H13V16" />
  </SvgIcon>
);

export default AlertIcon;
