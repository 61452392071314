import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const TrendDownIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M7.9282 14L1 2L14.8564 2L7.9282 14Z" />
  </SvgIcon>
);

export default TrendDownIcon;
