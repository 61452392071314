import { Theme } from '../../types';
import { borderRadius, color, sizing, spacing, typography } from './tokens';

const theme: Theme = {
  borderRadius,
  color,
  sizing,
  spacing,
  typography,
};

export default theme;
