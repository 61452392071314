import { styled } from '@mui/material';
import React, { forwardRef } from 'react';

export interface TableFooterProps extends React.ComponentProps<'tfoot'> {
  sticky?: boolean;
}

const TableFooterRoot = styled('tfoot')<TableFooterProps>(({ theme, sticky }) => ({
  ...(sticky && {
    position: 'sticky',
    bottom: 0,
    zIndex: 2,
    backgroundColor: theme.tokens.color['background.regular'],
  }),
}));

const TableFooter = forwardRef<React.ElementRef<'tfoot'>, TableFooterProps>(
  ({ children, ...rest }, ref) => (
    <TableFooterRoot ref={ref} {...rest}>
      {children}
    </TableFooterRoot>
  ),
);

export default TableFooter;
