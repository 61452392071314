import { buttonClasses } from '@mui/base';
import { styled, SxProps, Theme } from '@mui/material/styles';
import React from 'react';

import { IconWrapper, SvgIcon, SvgIconProps } from '../../icons';
import { ButtonBase, ButtonBaseProps } from '../ButtonBase';

export interface BackButtonProps extends ButtonBaseProps {
  sx?: SxProps<Theme>;
}

const BackButtonRoot = styled(ButtonBase)<BackButtonProps>(({ theme }) => ({
  gap: theme.tokens.spacing['none'],
  height: 40,

  backgroundColor: 'transparent',
  color: theme.tokens.color['text.subtle'],

  ...theme.tokens.typography['heading.button'],
  lineHeight: 1,

  [`&.${buttonClasses.disabled}`]: {
    opacity: 0.5,
  },
}));

// The chevron icon used for this button is not the one listed among the General icons, so it is soloed here
const ChevronLeftIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      d="M9.49402 11.0384L6.50593 8L9.49402 4.96161"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

const BackButton: React.FC<BackButtonProps> = ({ children = 'Back', ...rest }) => (
  <BackButtonRoot {...rest}>
    <IconWrapper>
      <ChevronLeftIcon size="icon.xxsmall" />
    </IconWrapper>
    {children}
  </BackButtonRoot>
);

export default BackButton;
