import { defaultTheme as tokens } from '@aily/ui-theme';
import { createTheme } from '@mui/material';

const defaultTheme = createTheme({
  palette: {
    text: {
      primary: tokens.color['text.regular'],
      secondary: tokens.color['text.subtle'],
    },
    background: {
      default: tokens.color['background.regular'],
      paper: tokens.color['background.light'],
    },
  },
  typography: tokens.typography,
  tokens,
});

export default defaultTheme;
