import { styled, SxProps, Theme } from '@mui/material/styles';
import React from 'react';

import { Stack } from '../Stack';
import { TrendIndicator, TrendIndicatorDirection } from '../TrendIndicator';
import { TrendValue } from '../TrendValue';
import { Typography } from '../Typography';

export interface KpiValueProps {
  sentiment?: KpiValueSentiment;
  trendDirection?: TrendIndicatorDirection;
  comparator?: React.ReactNode;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
}

export type KpiValueSentiment = 'positive' | 'negative';

const KpiValueRoot = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: theme.tokens.spacing['small'],
}));

const KpiValue: React.FC<KpiValueProps> = ({
  sentiment = 'positive',
  trendDirection = 'up',
  comparator,
  children,
  ...rest
}) => (
  <KpiValueRoot
    data-testid="kpi-value"
    data-x-sentiment={sentiment}
    data-x-trend-direction={trendDirection}
    {...rest}
  >
    {comparator ? (
      <Stack direction="row" spacing="xxsmall" alignItems="center">
        <TrendValue
          size="small"
          sentiment={sentiment}
          // Typography style inconsistencies
          sx={{ fontSize: 18 }}
        >
          {children}
        </TrendValue>
        <Typography variant="body.small-condensed" color="text.subtle">
          / {comparator}
        </Typography>
      </Stack>
    ) : (
      <TrendValue
        size="small"
        sentiment={sentiment}
        // Typography style inconsistencies
        sx={{ fontSize: 18 }}
      >
        {children}
      </TrendValue>
    )}
    <TrendIndicator direction={trendDirection} sentiment={sentiment} />
  </KpiValueRoot>
);

export default KpiValue;
