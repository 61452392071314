import { styled } from '@mui/material';
import React from 'react';

export interface TableCellProps {
  variant?: TableCellVariant;
  width?: number | string;
  align?: 'left' | 'center' | 'right';
  colSpan?: number;
  children?: React.ReactNode;
}

export type TableCellVariant = 'body' | 'head';

const TableCellRoot = styled('td')<TableCellProps>(
  ({ theme, variant = 'body', align = 'left' }) => ({
    height: 48,
    // Using boxShadow to avoid border issues with sticky headers and border-collapse
    boxShadow: `inset 0 -1px 0 ${theme.tokens.color['background.light']}`,
    padding: theme.spacing(0, theme.tokens.spacing['xsmall']),
    verticalAlign: 'inherit',
    textAlign: align,

    ...(variant === 'body' && {
      ...theme.tokens.typography['body.xsmall-condensed'],
      color: theme.tokens.color['text.regular'],
    }),

    ...(variant === 'head' && {
      ...theme.tokens.typography['body.small-bold-condensed'],
      color: theme.tokens.color['brand.aqua'],
    }),
  }),
);

const TableCell: React.FC<TableCellProps> = ({ variant = 'body', children, ...rest }) => (
  <TableCellRoot as={variant === 'head' ? 'th' : 'td'} variant={variant} {...rest}>
    {children}
  </TableCellRoot>
);

export default TableCell;
