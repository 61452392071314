import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const AiIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path d="M8.87506 7.17172C8.87506 7.29617 8.8324 7.3975 8.74706 7.47572C8.66528 7.55395 8.56573 7.59306 8.4484 7.59306C8.32751 7.59306 8.22617 7.55395 8.1444 7.47572C8.06262 7.3975 8.02173 7.29617 8.02173 7.17172C8.02173 7.04728 8.06262 6.94595 8.1444 6.86772C8.22617 6.78595 8.32751 6.74506 8.4484 6.74506C8.56573 6.74506 8.66528 6.78595 8.74706 6.86772C8.8324 6.94595 8.87506 7.04728 8.87506 7.17172Z" />
    <path d="M7.32636 7.52371H6.68102V4.73437H7.32636V7.52371ZM7.39569 3.91837C7.39569 4.03926 7.35658 4.13526 7.27836 4.20637C7.20014 4.27748 7.10769 4.31304 7.00102 4.31304C6.8908 4.31304 6.79658 4.27748 6.71836 4.20637C6.64014 4.13526 6.60102 4.03926 6.60102 3.91837C6.60102 3.79393 6.64014 3.69615 6.71836 3.62504C6.79658 3.55393 6.8908 3.51837 7.00102 3.51837C7.10769 3.51837 7.20014 3.55393 7.27836 3.62504C7.35658 3.69615 7.39569 3.79393 7.39569 3.91837Z" />
    <path d="M4.80806 7.07031C5.00717 7.07031 5.15828 7.05964 5.26139 7.03831V6.32364C5.22584 6.31298 5.17428 6.30231 5.10673 6.29164C5.03917 6.28098 4.96451 6.27564 4.88273 6.27564C4.81162 6.27564 4.73873 6.28098 4.66406 6.29164C4.59295 6.30231 4.52717 6.32187 4.46673 6.35031C4.40984 6.37876 4.36362 6.41964 4.32806 6.47298C4.29251 6.52276 4.27473 6.58676 4.27473 6.66498C4.27473 6.81787 4.32273 6.92453 4.41873 6.98498C4.51473 7.04187 4.64451 7.07031 4.80806 7.07031ZM4.75473 4.66498C4.96806 4.66498 5.14762 4.69164 5.29339 4.74498C5.43917 4.79831 5.55473 4.87298 5.64006 4.96898C5.72895 5.06498 5.79117 5.18231 5.82673 5.32098C5.86584 5.45609 5.88539 5.60542 5.88539 5.76898V7.45964C5.78584 7.48098 5.63473 7.50587 5.43206 7.53431C5.23295 7.56631 5.00717 7.58231 4.75473 7.58231C4.58762 7.58231 4.43473 7.56631 4.29606 7.53431C4.15739 7.50231 4.03828 7.45076 3.93873 7.37964C3.84273 7.30853 3.76628 7.21609 3.70939 7.10231C3.65606 6.98853 3.62939 6.84809 3.62939 6.68098C3.62939 6.52098 3.65962 6.38587 3.72006 6.27564C3.78406 6.16542 3.86939 6.07653 3.97606 6.00898C4.08273 5.93787 4.20539 5.88809 4.34406 5.85964C4.48628 5.82764 4.63384 5.81164 4.78673 5.81164C4.85784 5.81164 4.93251 5.81698 5.01073 5.82764C5.08895 5.83476 5.17251 5.84898 5.26139 5.87031V5.76364C5.26139 5.68898 5.25251 5.61787 5.23473 5.55031C5.21695 5.48276 5.18495 5.42409 5.13873 5.37431C5.09606 5.32098 5.03739 5.28009 4.96273 5.25164C4.89162 5.2232 4.80095 5.20898 4.69073 5.20898C4.54139 5.20898 4.40451 5.21964 4.28006 5.24098C4.15562 5.26231 4.05428 5.2872 3.97606 5.31564L3.89606 4.79298C3.97784 4.76453 4.09695 4.73609 4.25339 4.70764C4.40984 4.6792 4.57695 4.66498 4.75473 4.66498Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.52381 0.47619C5.52381 0.213198 5.73701 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 5.73701 0.213198 5.52381 0.47619 5.52381C0.739183 5.52381 0.952381 5.73701 0.952381 6C0.952381 8.78772 3.21228 11.0476 6 11.0476C8.78772 11.0476 11.0476 8.78772 11.0476 6C11.0476 3.21228 8.78772 0.952381 6 0.952381C5.73701 0.952381 5.52381 0.739183 5.52381 0.47619Z"
    />
  </SvgIcon>
);

export default AiIcon;
