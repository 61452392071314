import { EventTrackingConfig, getPathDetails, withEventTracking } from '@aily/analytics-service';
import { useAddToBrainStoryMutation } from '@aily/graphql-sdk/core';
import * as T from '@aily/graphql-sdk/schema';
import { AddToBrainButton } from '@aily/ui-components';
import React, { useCallback, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { usePage } from '../../hooks';
import { useModule } from '../../providers';

interface AddToBrainButtonContainerProps {
  story: T.Story;
  onAdd?: (story: T.Story) => void;
}

const AddToBrainButtonContainer: React.FC<AddToBrainButtonContainerProps> = ({ story, onAdd }) => {
  const location = useLocation();
  const [addToBrain, { loading }] = useAddToBrainStoryMutation();
  const [isAdded, setIsAdded] = useState(!!story.isAddedToBrain);
  const { page } = usePage();
  const module = useModule();

  const handleButtonClick = useCallback(() => {
    const parentPage = module?.pages?.find((p) =>
      matchPath(`${p.absolutePath}/*`, location.pathname),
    );
    const link = `${page?.absolutePath || parentPage?.absolutePath || location.pathname}${location.search}`;

    addToBrain({
      variables: {
        input: {
          storyId: story.id,
          addedToBrain: true,
          link,
        },
      },
      onCompleted: () => setIsAdded(true),
      onError: () => setIsAdded(false),
    });

    onAdd?.(story);
  }, [story, addToBrain, location, module, page, onAdd]);

  return (
    <AddToBrainButton
      status={loading ? 'loading' : isAdded ? 'added' : 'default'}
      onClick={handleButtonClick}
      data-testid="AddToBrainButton"
    />
  );
};

const trackingConfig: EventTrackingConfig<AddToBrainButtonContainerProps> = {
  onAdd: {
    eventName: 'story.clicked',
    getEventProps: (story) => {
      const { focusArea, focusAreaPath } = getPathDetails(story.link?.absolutePath ?? '');
      return {
        component: 'story',
        name: story.headline,
        component_id: story.id,
        component_type: story.storyType,
        component_value: 'add_to_brain',
        intent: 'click',
        item_type: 'button',
        module: story.storyDataModule,
        focus_area: focusArea,
        focus_area_path: focusAreaPath,
        event_version: '2.1.0',
      };
    },
  },
};

export default withEventTracking(AddToBrainButtonContainer, trackingConfig);
