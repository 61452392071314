import * as T from '@aily/graphql-sdk/schema';
import {
  Avatar as MuiAvatar,
  AvatarProps,
  Box,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { scaleLinear } from 'd3';
import { orderBy } from 'lodash-es';
import React from 'react';

import { ellipsis, lineClamp } from '../../theme/utils';
import StoryCoverImage from '../Recommender/StoryCoverImage';
import StoryIcon from '../Recommender/StoryIcon';
import { getStoryGradient } from '../Recommender/utils';
import { AgentAvatar } from './AgentAvatar';
interface StoryAvatarProps extends AvatarProps {
  story: T.Story;
}

const BackgroundBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.paper,
}));

const Avatar = styled(MuiAvatar)(({ theme }) => ({
  width: 73,
  height: 73,
  border: 'none',
  color: theme.palette.text.primary,
  backgroundColor: 'transparent',
  textAlign: 'center',
  transform: 'translateZ(0)',
  '& .MuiTypography-root': {
    padding: theme.spacing(0, 0.75),
  },
  '& .MuiSvgIcon-root': {
    zIndex: 1,
    fontSize: 28,
  },
}));

const StyledTypography = styled(
  ({ children, ...rest }: Omit<TypographyProps, 'children'> & { children: string }) => {
    const fontSizeScale = scaleLinear().domain([6, 10]).range([15, 11]).clamp(true);
    const longestWord = orderBy(children.split(' '), 'length', 'desc')[0];
    const fontSize = fontSizeScale(longestWord.length);
    return (
      <Typography {...rest} style={{ fontSize }}>
        {children}
      </Typography>
    );
  },
)(({ theme, children }) => ({
  padding: theme.spacing(0, 0.75),
  ...(children.includes(' ') ? lineClamp(3, { lineHeight: 1.1 }) : ellipsis()),
}));

const StoryAvatar: React.FC<StoryAvatarProps> = ({ story, ...rest }) => {
  if (story.storyType === T.StoryDisplayType.Media) {
    return (
      <Avatar>
        <BackgroundBox sx={{ background: getStoryGradient(story), opacity: 0.2 }} />
        <StoryIcon story={story} />
        <StoryCoverImage story={story} />
      </Avatar>
    );
  }

  // Handle case for displaying `story.tag`
  if (story.storyType === T.StoryDisplayType.AiDriveFocus && story.tag) {
    return (
      <Avatar {...rest}>
        <BackgroundBox sx={{ background: getStoryGradient(story), opacity: 0.2 }} />
        <StyledTypography>{story.tag}</StyledTypography>
      </Avatar>
    );
  }

  if (story.storyType === T.StoryDisplayType.Real) {
    return (
      <Avatar {...rest}>
        <AgentAvatar />
      </Avatar>
    );
  }

  // Default case, just display the story icon
  return (
    <Avatar {...rest}>
      <BackgroundBox sx={{ background: getStoryGradient(story), opacity: 0.2 }} />
      <StoryIcon story={story} />
    </Avatar>
  );
};

export default StoryAvatar;
