import * as T from '@aily/graphql-sdk/schema';
import { Center, ModuleIcon as Icon, useColor } from '@aily-labs/ui';
import React from 'react';

import { mapModuleCodeToLogoVariant } from '../../../utils';

interface Props {
  moduleCode: T.ModuleCode;
}
export type ModuleType =
  | 'PRO'
  | 'CLINOPS'
  | 'CMC'
  | 'CRM'
  | 'EBI'
  | 'FIN'
  | 'GRA'
  | 'GTM'
  | 'HR'
  | 'MNS'
  | 'PF'
  | 'PPL'
  | 'QA'
  | 'RND'
  | 'SPEND'
  | 'SUP'
  | 'SUPPLY';

const ModuleIcon: React.FC<Props> = ({ moduleCode }) => {
  const primaryAqua = useColor('primaryAqua');
  const moduleType = mapModuleCodeToLogoVariant(moduleCode)?.toUpperCase();

  return (
    <Center>
      <Icon moduleType={moduleType as ModuleType} height={65} color={primaryAqua} />
    </Center>
  );
};

export default React.memo(ModuleIcon);
