import { SvgIcon } from '@mui/material';

const SadFaceIcon = () => {
  return (
    <SvgIcon style={{ width: '40px', height: '40px' }} viewBox="0 0 40 40">
      <circle cx="20" cy="20" r="19" stroke="#F29A2E" stroke-width="2" fill="none" />
      <path d="M10.7695 26.1538H29.2311" stroke="#F29A2E" stroke-width="2" stroke-linecap="round" />
      <circle cx="14.6163" cy="14.6154" r="2.30769" fill="#F29A2E" />
      <circle cx="25.3839" cy="14.6154" r="2.30769" fill="#F29A2E" />
    </SvgIcon>
  );
};
export default SadFaceIcon;
