import { styled } from '@mui/material';
import React from 'react';

import { Button } from '../../buttons';
import { IconName, icons, SvgIconProps } from '../../icons';
import { Stack } from '../../Stack';
import { Typography } from '../../Typography';

export type StatusMessageProps = {
  title: string;
  description: string;
  icon: IconName;
  iconColor?: SvgIconProps['color'];
} & (
  | { buttonLabel: string; onButtonClick: () => void }
  | { buttonLabel?: never; onButtonClick?: never }
);

const StatusMessageRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.tokens.spacing['xxlarge'],
}));

const StatusMessage: React.FC<StatusMessageProps> = ({
  title,
  description,
  icon,
  iconColor = 'sentiment.warning',
  buttonLabel,
  onButtonClick,
}) => {
  const IconComponent = icons[icon];
  return (
    <StatusMessageRoot data-testid="status-message">
      <IconComponent size="icon.large" color={iconColor} data-testid="status-message__icon" />
      <Stack direction="column" spacing="xsmall" alignItems="center">
        <Typography variant="heading.H9" color="text.regular" data-testid="status-message__title">
          {title}
        </Typography>
        <Typography
          variant="body.regular-condensed"
          color="text.subtle"
          data-testid="status-message__description"
        >
          {description}
        </Typography>
      </Stack>
      {!!buttonLabel && onButtonClick && (
        <Button variant="secondary" onClick={onButtonClick} data-testid="status-message__button">
          {buttonLabel}
        </Button>
      )}
    </StatusMessageRoot>
  );
};

export default StatusMessage;
