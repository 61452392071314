import { createContext, ProviderProps, useContext } from 'react';

export interface Environment {
  SITE_NAME: string;
  SITE_DESCRIPTION: string;
  BUILD_VERSION: string;

  TENANT: string;
  ENV: string;

  OIDC_AUTH_URL: string;
  OIDC_CLIENT_ID: string;
  OIDC_REDIRECT_URL: string;
  OIDC_SCOPE: string;
  OIDC_DEBUG: string;

  API_URL: string;
  API_KEY: string;

  ANALYTICS_WRITE_KEY: string;
  ANALYTICS_CDN_URL: string;

  SENTRY_DSN: string;
  SENTRY_RELEASE: string;
  SENTRY_ENVIRONMENT: string;

  USER_DISCLAIMER_TITLE: string;
  USER_DISCLAIMER_MESSAGE: string;
  USER_DISCLAIMER_LOCAL_STORAGE: string;

  FEATURE_FLAGS_API_URL: string;
  FEATURE_FLAGS_TENANT_KEY: string;
}

export const baseEnvironment: Environment = {
  SITE_NAME: '',
  SITE_DESCRIPTION: '',
  BUILD_VERSION: '',

  TENANT: '',
  ENV: '',

  OIDC_AUTH_URL: '',
  OIDC_CLIENT_ID: '',
  OIDC_REDIRECT_URL: '',
  OIDC_SCOPE: '',
  OIDC_DEBUG: '',

  API_URL: '',
  API_KEY: '',

  ANALYTICS_WRITE_KEY: '',
  ANALYTICS_CDN_URL: '',

  SENTRY_DSN: '',
  SENTRY_RELEASE: '',
  SENTRY_ENVIRONMENT: '',

  USER_DISCLAIMER_TITLE: '',
  USER_DISCLAIMER_MESSAGE: '',
  USER_DISCLAIMER_LOCAL_STORAGE: '',

  FEATURE_FLAGS_API_URL: '',
  FEATURE_FLAGS_TENANT_KEY: '',
};

export const EnvironmentContext = createContext<Environment>(baseEnvironment);

export const EnvironmentProvider = ({ value, children }: ProviderProps<Environment>) => (
  <EnvironmentContext.Provider value={value}>{children}</EnvironmentContext.Provider>
);

export const useEnvironment = () => useContext(EnvironmentContext);
