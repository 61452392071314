import { buttonClasses } from '@mui/base';
import { alpha, styled } from '@mui/material/styles';
import React from 'react';

import { IconWrapper, LockOpenIcon } from '../../icons';
import { ButtonBase, ButtonBaseProps } from '../ButtonBase';

export interface RealsButtonProps extends ButtonBaseProps {}

const RealsButtonRoot = styled(ButtonBase)(({ theme }) => ({
  gap: theme.tokens.spacing['xsmall'],
  borderRadius: theme.tokens.borderRadius['large'],
  minWidth: 320,
  height: 40,

  backgroundColor: alpha(theme.tokens.color['brand.aqua'], 0.5),
  color: alpha(theme.tokens.color['text.regular'], 0.75),

  ...theme.tokens.typography['heading.button'],
  lineHeight: 1,

  [`&.${buttonClasses.disabled}`]: {
    backgroundColor: theme.tokens.color['background.elevated'],
    color: alpha(theme.tokens.color['text.regular'], 0.2),
  },
}));

const RealsButton: React.FC<RealsButtonProps> = ({ children, disabled, ...rest }) => (
  <RealsButtonRoot disabled={disabled} {...rest}>
    {disabled && (
      <IconWrapper>
        <LockOpenIcon size="icon.xxsmall" />
      </IconWrapper>
    )}
    {children}
  </RealsButtonRoot>
);

export default RealsButton;
