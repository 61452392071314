import { ColorTokenValue } from '../../../types';

// Define token object with strict type checking
const color = {
  'neutral.0': '#ffffff',
  'neutral.200': '#a6a6a6',
  'neutral.400': '#808080',
  'neutral.600': '#595959',
  'neutral.800': '#404040',
  'neutral.900': '#1a1a1a',
  'neutral.1000': '#000000',
  'neutral.white': '#ffffff',
  'neutral.grey-light': '#a6a6a6',
  'neutral.grey-lighter': '#808080',
  'neutral.grey': '#595959',
  'neutral.grey-darker': '#404040',
  'neutral.grey-dark': '#1a1a1a',
  'neutral.black': '#000000',
  'green.500': '#36bf74',
  'green.700': '#0e331f',
  'aqua.500': '#31d4b6',
  'aqua.700': '#259f88',
  'mint.500': '#48ff9b',
  'pink.500': '#ff3469',
  'pink.700': '#330a15',
  'orange.500': '#f29a2e',
  'blue.900': '#0e111a',
  'brand.mint': '#48ff9b',
  'brand.green': '#36bf74',
  'brand.aqua': '#31d4b6',
  'brand.pink': '#ff3469',
  'brand.orange': '#f29a2e',
  'text.regular': '#ffffff',
  'text.subtle': '#a6a6a6',
  'text.disabled': '#808080',
  'text.accent': '#31d4b6',
  'text.success': '#48ff9b',
  'text.warning': '#f29a2e',
  'text.danger': '#ff3469',
  'background.regular': '#000000',
  'background.light': '#1a1a1a',
  'background.positive': '#0e331f',
  'background.elevated': '#0e111a',
  'background.highlighted': '#259f88',
  'background.negative': '#330a15',
  'sentiment.neutral': '#31d4b6',
  'sentiment.positive': '#48ff9b',
  'sentiment.warning': '#f29a2e',
  'sentiment.negative': '#ff3469',
  'gradient.mint.horizontal': 'linear-gradient(90deg, #31d4b6 0%, #48ff9b 100%)',
  'gradient.mint.vertical': 'linear-gradient(0deg, #48ff9b 0%, #31d4b6 100%)',
  'gradient.mint.vertical-inverse': 'linear-gradient(0deg, #31d4b6 0%, #48ff9b 100%)',
  'gradient.orange.vertical': 'linear-gradient(0deg, #f29a2e 0%, #ff5005 100%)',
  'gradient.orange.transparent':
    'linear-gradient(180deg, #f29a2e 25%, rgba(242, 154, 46, 0.00) 100%)',
  'gradient.pink.vertical': 'linear-gradient(180deg, #ff3469 100%, #ff3469 100%)',
  'gradient.pink.transparent': 'linear-gradient(180deg, #eb4a6c 0%, rgba(237, 30, 117, 0.00) 100%)',
  'gradient.pink.semi-transparent': 'linear-gradient(180deg, #eb4a6cb3 0%, #ed1e7533 100%)',
  'gradient.aqua.vertical': 'linear-gradient(135deg, #31d4b6 0%, rgba(49, 212, 182, 0.80) 25%)',
  'gradient.aqua.transparent':
    'linear-gradient(180deg, #31d4b6 25%, rgba(49, 212, 182, 0.00) 100%)',
  'gradient.aqua.transparent-dark': 'linear-gradient(0deg, rgba(37, 159, 136, 0) 0%, #259f88 100%)',
  'gradient.green.vertical': 'linear-gradient(0deg, #48ff9b 0%, #2b995c 100%)',
  'gradient.green.transparent': 'linear-gradient(0deg, rgba(72, 255, 155, 0.00) 0%, #48ff9b 100%)',
  'gradient.green.semi-transparent': 'linear-gradient(0deg, #48ff9b33 0%, #48ff9b99 100%)',
  'gradient.blue.vertical': 'linear-gradient(0deg, #4bade9 0%, #224e69 100%)',
  'gradient.blue.transparent': 'linear-gradient(180deg, #4bade9 0%, rgba(93, 119, 164, 0.00) 100%)',
  'gradient.purple.vertical': 'linear-gradient(0deg, #684e9c 0%, #413161 100%)',
  'gradient.purple.transparent':
    'linear-gradient(180deg, #684e9c 0%, rgba(104, 78, 156, 0.00) 100%)',
} as const satisfies Record<string, ColorTokenValue>;

export default color;
