import { SizingTokenValue } from '../../../types';

// Define token object with strict type checking
const sizing = {
  'icon.xxsmall': '16px',
  'icon.xsmall': '20px',
  'icon.small': '24px',
  'icon.medium': '28px',
  'icon.large': '40px',
} as const satisfies Record<string, SizingTokenValue>;

export default sizing;
