import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const PinIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="34" height="34" viewBox="0 0 34 34" {...props}>
    <path d="M21.3031 20.8781L21.5422 25.2078L19.9484 26.8016L14.3703 21.2234L7.96875 27.625H6.375V26.0312L12.7766 19.6297L7.19844 14.0516L8.79219 12.4578L12.9625 12.5375L21.7813 3.71875L20.4531 2.39063L22.0469 0.796875L33.0438 11.7938L31.45 13.3875L30.1219 12.0594L21.3031 20.8781ZM10.9438 14.6094L19.2313 22.8969L19.125 19.8688L28.5281 10.4656L23.375 5.3125L13.9719 14.7156L10.9438 14.6094Z" />
  </SvgIcon>
);

export default PinIcon;
