import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const PlusIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="34" height="34" viewBox="0 0 34 34" {...props}>
    <path d="M15.5829 18.4169H7.08295V15.5835H15.5829V7.08353H18.4163V15.5835H26.9163V18.4169H18.4163V26.9169H15.5829V18.4169Z" />
  </SvgIcon>
);

export default PlusIcon;
