import { styled, Typography } from '@mui/material';

import FaceSadIcon from './SadFaceIcon';

interface ProfileNoDataDisclaimerProps {
  message: string;
}

const FullScreenLayout = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '60vh',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  gap: '12px',
}));

export const ProfileNoDataMessage = ({ message }: ProfileNoDataDisclaimerProps) => {
  return (
    <FullScreenLayout>
      <FaceSadIcon />
      <Typography variant="body" sx={{ fontSize: 16, lineHeight: '180%', color: 'text.secondary' }}>
        {message}
      </Typography>
    </FullScreenLayout>
  );
};
