import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const SendIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M21.2646 2.70091C22.4572 2.70663 23.2014 4.08775 22.6097 5.19721L13.1211 22.9893C12.4073 24.3277 10.5263 24.0021 10.2423 22.4909L8.33865 12.3609L1.10254 5.5296C0.0230679 4.51051 0.700326 2.6023 2.13904 2.6092L21.2646 2.70091ZM9.92391 12.3434L11.769 22.1616L20.4949 5.79952L9.92391 12.3434ZM19.7042 4.36198L2.13207 4.27771L9.14516 10.8985L19.7042 4.36198Z" />
  </SvgIcon>
);

export default SendIcon;
