import { styled } from '@mui/material';
import React, { forwardRef } from 'react';

export interface TableRowProps extends React.ComponentProps<'tr'> {}

const TableRowRoot = styled('tr')({
  verticalAlign: 'middle',
  color: 'inherit',
});

const TableRow = forwardRef<React.ElementRef<'tr'>, TableRowProps>(({ children, ...rest }, ref) => (
  <TableRowRoot ref={ref} {...rest}>
    {children}
  </TableRowRoot>
));

export default TableRow;
