import { styled } from '@mui/material';
import React, { forwardRef } from 'react';

export interface TableHeadProps extends React.ComponentProps<'thead'> {
  sticky?: boolean;
}

const TableHeadRoot = styled('thead')<TableHeadProps>(({ theme, sticky }) => ({
  ...(sticky && {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    backgroundColor: theme.tokens.color['background.regular'],
  }),
}));

const TableHead = forwardRef<React.ElementRef<'thead'>, TableHeadProps>(
  ({ children, ...rest }, ref) => (
    <TableHeadRoot ref={ref} {...rest}>
      {children}
    </TableHeadRoot>
  ),
);

export default TableHead;
