import React from 'react';

import AiIcon from './AiIcon';
import AlertIcon from './AlertIcon';
import BrainIcon from './BrainIcon';
import CheckIcon from './CheckIcon';
import ChevronRightSmall from './ChevronRightSmall';
import LockOpenIcon from './LockOpenIcon';
import PinIcon from './PinIcon';
import PlusIcon from './PlusIcon';
import ProductionIcon from './ProductionIcon';
import ResetIcon from './ResetIcon';
import SadFaceIcon from './SadFaceIcon';
import SendIcon from './SendIcon';
import { SvgIconProps } from './SvgIcon';
import TrendDownIcon from './TrendDownIcon';
import TrendUpIcon from './TrendUpIcon';
import WifiOffIcon from './WifiOffIcon';

export const icons = {
  ai: AiIcon,
  alert: AlertIcon,
  brain: BrainIcon,
  check: CheckIcon,
  chevronRightSmall: ChevronRightSmall,
  lockOpen: LockOpenIcon,
  pin: PinIcon,
  plus: PlusIcon,
  production: ProductionIcon,
  reset: ResetIcon,
  sadFace: SadFaceIcon,
  send: SendIcon,
  trendDown: TrendDownIcon,
  trendUp: TrendUpIcon,
  wifiOff: WifiOffIcon,
} satisfies Record<string, React.ComponentType<SvgIconProps>>;

export type IconName = keyof typeof icons;
