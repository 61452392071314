import { styled } from '@mui/material';
import React, { forwardRef } from 'react';

export interface TableBodyProps extends React.ComponentProps<'tbody'> {}

const TableBodyRoot = styled('tbody')({});

const TableBody = forwardRef<React.ElementRef<'tbody'>, TableBodyProps>(
  ({ children, ...rest }, ref) => (
    <TableBodyRoot ref={ref} {...rest}>
      {children}
    </TableBodyRoot>
  ),
);

export default TableBody;
