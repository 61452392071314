import { CSSProperties } from 'react';

/**
 * Returns styles for text color or gradient.
 *
 * @param color - Color or gradient string.
 * @returns CSS properties for text color.
 */
export const getTextColorStyles = (color?: string): CSSProperties => {
  const isGradient = color?.startsWith('linear-gradient');

  return isGradient
    ? {
        background: color,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      }
    : {
        color,
      };
};
