import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const TrendUpIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M7.9282 2L1 14L14.8564 14L7.9282 2Z" />
  </SvgIcon>
);

export default TrendUpIcon;
