import { NonGradientColorTokenKey } from '@aily/ui-theme';
import { arc, easeCubicInOut, interpolate, select } from 'd3';
import React, { useEffect, useRef } from 'react';

import { useTokenValue } from '../../../hooks';

export interface HalfGaugeProps {
  value?: number;
  width?: number;
  height?: number;
  color?: NonGradientColorTokenKey;
  backgroundColor?: NonGradientColorTokenKey;
}

const HalfGauge: React.FC<HalfGaugeProps> = ({
  value = 0,
  width = 40,
  height = 20,
  color = 'aqua.500',
  backgroundColor = 'neutral.400',
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const prevValueRef = useRef(0);
  const colorValue = useTokenValue('color', color);
  const backgroundColorValue = useTokenValue('color', backgroundColor);

  useEffect(() => {
    if (!svgRef.current) return;

    // Calculate radius to fit the half-circle within the given width and height
    const radius = Math.min(width, height * 2) / 2;
    const thickness = radius / 2;
    const arcGenerator = arc<number>()
      .innerRadius(radius - thickness)
      .outerRadius(radius)
      .startAngle(-Math.PI / 2)
      .endAngle((d) => -Math.PI / 2 + d * Math.PI);

    const svg = select(svgRef.current);
    svg.selectAll('*').remove(); // Clear existing content

    // Background arc
    svg
      .append('path')
      .datum(1) // Full progress for background
      .attr('d', arcGenerator)
      .attr('fill', backgroundColorValue)
      .attr('transform', `translate(${width / 2}, ${height})`);

    // Ensure value is clamped between 0 and 1
    const clampedValue = Math.max(0, Math.min(1, value));

    // Progress arc
    const foreground = svg
      .append('path')
      .datum(clampedValue)
      .attr('d', arcGenerator)
      .attr('fill', colorValue)
      .attr('transform', `translate(${width / 2}, ${height})`);

    // Animate the progress from the previous value
    const prevValue = prevValueRef.current;
    foreground
      .transition()
      .duration(500)
      .ease(easeCubicInOut)
      .attrTween('d', function (d) {
        const interpolator = interpolate(prevValue, d);
        return (t) => arcGenerator(interpolator(t)) as string;
      });

    // Update the previous value
    prevValueRef.current = clampedValue;
  }, [value, width, height, colorValue, backgroundColorValue]);

  return <svg ref={svgRef} width={width} height={height} viewBox={`0 0 ${width} ${height}`} />;
};

export default React.memo(HalfGauge);
