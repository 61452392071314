import { buttonClasses } from '@mui/base';
import { styled } from '@mui/material/styles';
import React from 'react';

import { SvgIcon, SvgIconProps } from '../../icons';
import { ButtonBase, ButtonBaseProps } from '../ButtonBase';

export interface ExpandButtonProps extends ButtonBaseProps {
  expanded?: boolean;
}

const ExpandButtonRoot = styled(ButtonBase)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.tokens.color['text.regular'],

  ...theme.tokens.typography['body.regular-condensed'],
  lineHeight: 1,

  [`&.${buttonClasses.disabled}`]: {
    opacity: 0.5,
  },
}));

const IconWrapper = styled('span')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: theme.tokens.spacing['xsmall'],
}));

const ChevronDownIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="16" height="17" viewBox="0 0 16 17" {...props}>
    <path
      d="M4.06641 6.81689L7.74069 10.4912L11.415 6.81689"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

const ChevronUpIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="16" height="17" viewBox="0 0 16 17" {...props}>
    <path
      d="M11.415 10.4912L7.74069 6.81689L4.06641 10.4912"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

const ExpandButton: React.FC<ExpandButtonProps> = ({ expanded, children, ...rest }) => {
  const IconComponent = expanded ? ChevronUpIcon : ChevronDownIcon;
  return (
    <ExpandButtonRoot {...rest}>
      {children}
      <IconWrapper>
        <IconComponent size="icon.xxsmall" color="neutral.400" />
      </IconWrapper>
    </ExpandButtonRoot>
  );
};

export default ExpandButton;
