import { gql } from '@apollo/client';

import type * as T from '../../schema/__generated__/types';
export type ChartAxisFields_ChartAxisCategoryViewResult_Fragment = {
  __typename?: 'ChartAxisCategoryViewResult';
  categories?: Array<string> | null;
  renderer?: unknown | null;
  axisType: T.AxisType;
};

export type ChartAxisFields_ChartAxisPercentViewResult_Fragment = {
  __typename?: 'ChartAxisPercentViewResult';
  renderer?: unknown | null;
  axisType: T.AxisType;
};

export type ChartAxisFields_ChartAxisValueViewResult_Fragment = {
  __typename?: 'ChartAxisValueViewResult';
  renderer?: unknown | null;
  axisType: T.AxisType;
  hasRawDataPointValues?: boolean | null;
};

export type ChartAxisFieldsFragment =
  | ChartAxisFields_ChartAxisCategoryViewResult_Fragment
  | ChartAxisFields_ChartAxisPercentViewResult_Fragment
  | ChartAxisFields_ChartAxisValueViewResult_Fragment;

export type ChartSeriesFieldsFragment = {
  __typename?: 'ChartSeries';
  id: number;
  seriesType: T.SeriesType;
  linkedTo?: number | null;
  name: string;
  color?: T.Color | null;
  lineStyle?: T.LineStyle | null;
  showInLegend?: boolean | null;
  showInTooltip: boolean;
  showLastPointMarker: boolean;
  tooltipRangeFormat: T.TooltipRangeFormat;
  tooltipTitleLow?: string | null;
  tooltipTitleHigh?: string | null;
  tooltipTitleMedian?: string | null;
  tooltipCustomType: T.TooltipCustomType;
  renderer?: unknown | null;
  data?: Array<{ __typename?: 'ChartSeriesDataPoint' } & ChartSeriesDataPointFieldsFragment> | null;
};

export type ChartSeriesDataPointFieldsFragment = {
  __typename?: 'ChartSeriesDataPoint';
  x?: number | null;
  y?:
    | ({ __typename?: 'ChartSeriesDataPointValue' } & ChartSeriesDataPointValueFieldsFragment)
    | null;
};

export type ChartSeriesDataPointValueFieldsFragment = {
  __typename?: 'ChartSeriesDataPointValue';
  value?: ({ __typename?: 'FormattedValue' } & FormattedValueFieldsFragment) | null;
  high?: ({ __typename?: 'FormattedValue' } & FormattedValueFieldsFragment) | null;
  low?: ({ __typename?: 'FormattedValue' } & FormattedValueFieldsFragment) | null;
  median?: ({ __typename?: 'FormattedValue' } & FormattedValueFieldsFragment) | null;
  variance?: ({ __typename?: 'VarianceResult' } & VarianceFieldsFragment) | null;
};

export type ComponentFields_ButtonComponent_Fragment = {
  __typename?: 'ButtonComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_CardsComponent_Fragment = {
  __typename?: 'CardsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_CashFlowKpiComponent_Fragment = {
  __typename?: 'CashFlowKpiComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_ChartComponent_Fragment = {
  __typename?: 'ChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_ColumnLayoutComponent_Fragment = {
  __typename?: 'ColumnLayoutComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_ComponentBase_Fragment = {
  __typename?: 'ComponentBase';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_DemoHomeChartComponent_Fragment = {
  __typename?: 'DemoHomeChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_DemoHomeMicroChartComponent_Fragment = {
  __typename?: 'DemoHomeMicroChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_DialsComponent_Fragment = {
  __typename?: 'DialsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_EbiHomeCategoriesDialsComponent_Fragment = {
  __typename?: 'EbiHomeCategoriesDialsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_EbiHomeDetailsChartComponent_Fragment = {
  __typename?: 'EbiHomeDetailsChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_EbiHomeDetailsChartHeaderComponent_Fragment = {
  __typename?: 'EbiHomeDetailsChartHeaderComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_EbiHomeDetailsDriversChartComponent_Fragment = {
  __typename?: 'EbiHomeDetailsDriversChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_EbiHomeDetailsDriversChartHeaderComponent_Fragment = {
  __typename?: 'EbiHomeDetailsDriversChartHeaderComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_EbiHomeDetailsDriversComponent_Fragment = {
  __typename?: 'EbiHomeDetailsDriversComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_EbiHomeDetailsRecommendationsComponent_Fragment = {
  __typename?: 'EbiHomeDetailsRecommendationsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_FilterComponent_Fragment = {
  __typename?: 'FilterComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_FiltersComponent_Fragment = {
  __typename?: 'FiltersComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_FinGtnOpexAiRecommendationComponent_Fragment = {
  __typename?: 'FinGtnOpexAiRecommendationComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_FinHomePageCardsComponent_Fragment = {
  __typename?: 'FinHomePageCardsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_FinPnlAiRecommendationComponent_Fragment = {
  __typename?: 'FinPnlAiRecommendationComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GraFacilitatedPathwaysTableComponent_Fragment = {
  __typename?: 'GraFacilitatedPathwaysTableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GraHomeCardsComponent_Fragment = {
  __typename?: 'GraHomeCardsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GraHomeDialsComponent_Fragment = {
  __typename?: 'GraHomeDialsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GraRecommendedPathwaysDetailsComponent_Fragment = {
  __typename?: 'GraRecommendedPathwaysDetailsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GraRecommendedPathwaysTableComponent_Fragment = {
  __typename?: 'GraRecommendedPathwaysTableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GridComponent_Fragment = {
  __typename?: 'GridComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmHomeCardsComponent_Fragment = {
  __typename?: 'GtmHomeCardsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmHomeDialComponent_Fragment = {
  __typename?: 'GtmHomeDialComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmHomepageCardsComponent_Fragment = {
  __typename?: 'GtmHomepageCardsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmImpactRecommendationComponent_Fragment = {
  __typename?: 'GtmImpactRecommendationComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmImpactTableComponent_Fragment = {
  __typename?: 'GtmImpactTableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmImpactTableHeaderComponent_Fragment = {
  __typename?: 'GtmImpactTableHeaderComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmInteractionsChartComponent_Fragment = {
  __typename?: 'GtmInteractionsChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmInteractionsTableComponent_Fragment = {
  __typename?: 'GtmInteractionsTableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmPulseDetailsChartComponent_Fragment = {
  __typename?: 'GtmPulseDetailsChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmPulseDetailsChartHeaderComponent_Fragment = {
  __typename?: 'GtmPulseDetailsChartHeaderComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmPulseDetailsDriversComponent_Fragment = {
  __typename?: 'GtmPulseDetailsDriversComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmPulseDialComponent_Fragment = {
  __typename?: 'GtmPulseDialComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmPulseLeaderboardListComponent_Fragment = {
  __typename?: 'GtmPulseLeaderboardListComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmPulseLeaderboardPositionComponent_Fragment = {
  __typename?: 'GtmPulseLeaderboardPositionComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmPulseRecommendedActionsComponent_Fragment = {
  __typename?: 'GtmPulseRecommendedActionsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmReachTableComponent_Fragment = {
  __typename?: 'GtmReachTableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_GtmResourceAllocationTableComponent_Fragment = {
  __typename?: 'GtmResourceAllocationTableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_HeaderRowComponent_Fragment = {
  __typename?: 'HeaderRowComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_HeaderSimpleComponent_Fragment = {
  __typename?: 'HeaderSimpleComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_KpiComponent_Fragment = {
  __typename?: 'KpiComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_ListComponent_Fragment = {
  __typename?: 'ListComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_MarkdownComponent_Fragment = {
  __typename?: 'MarkdownComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_MicroChartComponent_Fragment = {
  __typename?: 'MicroChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_MnsDeviationsDetailsComponent_Fragment = {
  __typename?: 'MnsDeviationsDetailsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_MnsDeviationsDialComponent_Fragment = {
  __typename?: 'MnsDeviationsDialComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_NavigationTabsComponent_Fragment = {
  __typename?: 'NavigationTabsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PlailistComponent_Fragment = {
  __typename?: 'PlailistComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PnlRebatesBreakdownChartComponent_Fragment = {
  __typename?: 'PnlRebatesBreakdownChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PnlRebatesBreakdownChartHeaderComponent_Fragment = {
  __typename?: 'PnlRebatesBreakdownChartHeaderComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PnlRebatesBreakdownTableComponent_Fragment = {
  __typename?: 'PnlRebatesBreakdownTableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PnlRebatesInsightsCardsComponent_Fragment = {
  __typename?: 'PnlRebatesInsightsCardsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PnlRebatesInsightsHeaderComponent_Fragment = {
  __typename?: 'PnlRebatesInsightsHeaderComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PplHomeCardsComponent_Fragment = {
  __typename?: 'PplHomeCardsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PplHomeCategoriesDialsComponent_Fragment = {
  __typename?: 'PplHomeCategoriesDialsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PplHomeDetailsChartComponent_Fragment = {
  __typename?: 'PplHomeDetailsChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PplHomeDetailsChartHeaderComponent_Fragment = {
  __typename?: 'PplHomeDetailsChartHeaderComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PplHomeDetailsDriversChartComponent_Fragment = {
  __typename?: 'PplHomeDetailsDriversChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PplHomeDetailsDriversChartHeaderComponent_Fragment = {
  __typename?: 'PplHomeDetailsDriversChartHeaderComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PplHomeDetailsDriversComponent_Fragment = {
  __typename?: 'PplHomeDetailsDriversComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PplHomeDetailsRecommendationsComponent_Fragment = {
  __typename?: 'PplHomeDetailsRecommendationsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_PplHomeDialComponent_Fragment = {
  __typename?: 'PplHomeDialComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_ProAgentDetailsComponent_Fragment = {
  __typename?: 'ProAgentDetailsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_ProDailyAgentComponent_Fragment = {
  __typename?: 'ProDailyAgentComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_ProHomeFeedComponent_Fragment = {
  __typename?: 'ProHomeFeedComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RecommendationsActionsComponent_Fragment = {
  __typename?: 'RecommendationsActionsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RecommendationsBreakdownComponent_Fragment = {
  __typename?: 'RecommendationsBreakdownComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RecommenderComponent_Fragment = {
  __typename?: 'RecommenderComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndHomeDialsComponent_Fragment = {
  __typename?: 'RndHomeDialsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndInsightsBusinessDevelopmentAndLicensingDriversComponent_Fragment = {
  __typename?: 'RndInsightsBusinessDevelopmentAndLicensingDriversComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndInsightsBusinessDevelopmentAndLicensingTableComponent_Fragment = {
  __typename?: 'RndInsightsBusinessDevelopmentAndLicensingTableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndInventoryRecommendationsBreakdownComponent_Fragment = {
  __typename?: 'RndInventoryRecommendationsBreakdownComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndInventoryRecommendationsComponent_Fragment = {
  __typename?: 'RndInventoryRecommendationsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndInventoryRootCausesComponent_Fragment = {
  __typename?: 'RndInventoryRootCausesComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndStudyDesignFormComponent_Fragment = {
  __typename?: 'RndStudyDesignFormComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndStudyDesignsSiteDistributionTableComponent_Fragment = {
  __typename?: 'RndStudyDesignsSiteDistributionTableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndStudyDesignsTableComponent_Fragment = {
  __typename?: 'RndStudyDesignsTableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndTrialsAiPosDriversComponent_Fragment = {
  __typename?: 'RndTrialsAiPosDriversComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndTrialsKpiCategoriesComponent_Fragment = {
  __typename?: 'RndTrialsKpiCategoriesComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndTrialsKpiDetailTableComponent_Fragment = {
  __typename?: 'RndTrialsKpiDetailTableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndTrialsRndInvestmentsPnlTableComponent_Fragment = {
  __typename?: 'RndTrialsRndInvestmentsPnlTableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndValueOverviewChartComponent_Fragment = {
  __typename?: 'RndValueOverviewChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_RndValueOverviewChartHeaderComponent_Fragment = {
  __typename?: 'RndValueOverviewChartHeaderComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendCmoPulseDialComponent_Fragment = {
  __typename?: 'SpendCmoPulseDialComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendCmoPulseDriversChartComponent_Fragment = {
  __typename?: 'SpendCmoPulseDriversChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendCmoPulseDriversListComponent_Fragment = {
  __typename?: 'SpendCmoPulseDriversListComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendCmoPulseKeyDriversChartComponent_Fragment = {
  __typename?: 'SpendCmoPulseKeyDriversChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendCmoPulseKeyDriversListComponent_Fragment = {
  __typename?: 'SpendCmoPulseKeyDriversListComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendCmoPulseLeaderboardListComponent_Fragment = {
  __typename?: 'SpendCmoPulseLeaderboardListComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendCmoPulseLeaderboardPositionComponent_Fragment = {
  __typename?: 'SpendCmoPulseLeaderboardPositionComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendCmoPulseRecommendedActionsComponent_Fragment = {
  __typename?: 'SpendCmoPulseRecommendedActionsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendGlocalPulseDialComponent_Fragment = {
  __typename?: 'SpendGlocalPulseDialComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendGlocalPulseDriversChartComponent_Fragment = {
  __typename?: 'SpendGlocalPulseDriversChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendGlocalPulseDriversListComponent_Fragment = {
  __typename?: 'SpendGlocalPulseDriversListComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendGlocalPulseKeyDriversChartComponent_Fragment = {
  __typename?: 'SpendGlocalPulseKeyDriversChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendGlocalPulseKeyDriversListComponent_Fragment = {
  __typename?: 'SpendGlocalPulseKeyDriversListComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendGlocalPulseLeaderboardListComponent_Fragment = {
  __typename?: 'SpendGlocalPulseLeaderboardListComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendGlocalPulseLeaderboardPositionComponent_Fragment = {
  __typename?: 'SpendGlocalPulseLeaderboardPositionComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendGlocalPulseRecommendedActionsComponent_Fragment = {
  __typename?: 'SpendGlocalPulseRecommendedActionsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendHomeCardsComponent_Fragment = {
  __typename?: 'SpendHomeCardsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendHomeDialComponent_Fragment = {
  __typename?: 'SpendHomeDialComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendProcurementChartComponent_Fragment = {
  __typename?: 'SpendProcurementChartComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendProcurementTableComponent_Fragment = {
  __typename?: 'SpendProcurementTableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SpendTrendingComponent_Fragment = {
  __typename?: 'SpendTrendingComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_StoryListComponent_Fragment = {
  __typename?: 'StoryListComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SummaryInfoComponent_Fragment = {
  __typename?: 'SummaryInfoComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SupplyHomeCardsComponent_Fragment = {
  __typename?: 'SupplyHomeCardsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SupplyHomeDialsComponent_Fragment = {
  __typename?: 'SupplyHomeDialsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SupplyLowInventoryRiskRootCausesComponent_Fragment = {
  __typename?: 'SupplyLowInventoryRiskRootCausesComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SupplyPulseLeaderboardListComponent_Fragment = {
  __typename?: 'SupplyPulseLeaderboardListComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SupplyShortDatedRootCausesComponent_Fragment = {
  __typename?: 'SupplyShortDatedRootCausesComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_SupportComponent_Fragment = {
  __typename?: 'SupportComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_TableComponent_Fragment = {
  __typename?: 'TableComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_TableSwitcherComponent_Fragment = {
  __typename?: 'TableSwitcherComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_TimelineComponent_Fragment = {
  __typename?: 'TimelineComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_UserAnalyticsComponent_Fragment = {
  __typename?: 'UserAnalyticsComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_UserPrioritiesComponent_Fragment = {
  __typename?: 'UserPrioritiesComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFields_VideoComponent_Fragment = {
  __typename?: 'VideoComponent';
  id: string;
  code: string;
  componentType: T.ComponentType;
  icon: T.ComponentIcon;
};

export type ComponentFieldsFragment =
  | ComponentFields_ButtonComponent_Fragment
  | ComponentFields_CardsComponent_Fragment
  | ComponentFields_CashFlowKpiComponent_Fragment
  | ComponentFields_ChartComponent_Fragment
  | ComponentFields_ColumnLayoutComponent_Fragment
  | ComponentFields_ComponentBase_Fragment
  | ComponentFields_DemoHomeChartComponent_Fragment
  | ComponentFields_DemoHomeMicroChartComponent_Fragment
  | ComponentFields_DialsComponent_Fragment
  | ComponentFields_EbiHomeCategoriesDialsComponent_Fragment
  | ComponentFields_EbiHomeDetailsChartComponent_Fragment
  | ComponentFields_EbiHomeDetailsChartHeaderComponent_Fragment
  | ComponentFields_EbiHomeDetailsDriversChartComponent_Fragment
  | ComponentFields_EbiHomeDetailsDriversChartHeaderComponent_Fragment
  | ComponentFields_EbiHomeDetailsDriversComponent_Fragment
  | ComponentFields_EbiHomeDetailsRecommendationsComponent_Fragment
  | ComponentFields_FilterComponent_Fragment
  | ComponentFields_FiltersComponent_Fragment
  | ComponentFields_FinGtnOpexAiRecommendationComponent_Fragment
  | ComponentFields_FinHomePageCardsComponent_Fragment
  | ComponentFields_FinPnlAiRecommendationComponent_Fragment
  | ComponentFields_GraFacilitatedPathwaysTableComponent_Fragment
  | ComponentFields_GraHomeCardsComponent_Fragment
  | ComponentFields_GraHomeDialsComponent_Fragment
  | ComponentFields_GraRecommendedPathwaysDetailsComponent_Fragment
  | ComponentFields_GraRecommendedPathwaysTableComponent_Fragment
  | ComponentFields_GridComponent_Fragment
  | ComponentFields_GtmHomeCardsComponent_Fragment
  | ComponentFields_GtmHomeDialComponent_Fragment
  | ComponentFields_GtmHomepageCardsComponent_Fragment
  | ComponentFields_GtmImpactRecommendationComponent_Fragment
  | ComponentFields_GtmImpactTableComponent_Fragment
  | ComponentFields_GtmImpactTableHeaderComponent_Fragment
  | ComponentFields_GtmInteractionsChartComponent_Fragment
  | ComponentFields_GtmInteractionsTableComponent_Fragment
  | ComponentFields_GtmPulseDetailsChartComponent_Fragment
  | ComponentFields_GtmPulseDetailsChartHeaderComponent_Fragment
  | ComponentFields_GtmPulseDetailsDriversComponent_Fragment
  | ComponentFields_GtmPulseDialComponent_Fragment
  | ComponentFields_GtmPulseLeaderboardListComponent_Fragment
  | ComponentFields_GtmPulseLeaderboardPositionComponent_Fragment
  | ComponentFields_GtmPulseRecommendedActionsComponent_Fragment
  | ComponentFields_GtmReachTableComponent_Fragment
  | ComponentFields_GtmResourceAllocationTableComponent_Fragment
  | ComponentFields_HeaderRowComponent_Fragment
  | ComponentFields_HeaderSimpleComponent_Fragment
  | ComponentFields_KpiComponent_Fragment
  | ComponentFields_ListComponent_Fragment
  | ComponentFields_MarkdownComponent_Fragment
  | ComponentFields_MicroChartComponent_Fragment
  | ComponentFields_MnsDeviationsDetailsComponent_Fragment
  | ComponentFields_MnsDeviationsDialComponent_Fragment
  | ComponentFields_NavigationTabsComponent_Fragment
  | ComponentFields_PlailistComponent_Fragment
  | ComponentFields_PnlRebatesBreakdownChartComponent_Fragment
  | ComponentFields_PnlRebatesBreakdownChartHeaderComponent_Fragment
  | ComponentFields_PnlRebatesBreakdownTableComponent_Fragment
  | ComponentFields_PnlRebatesInsightsCardsComponent_Fragment
  | ComponentFields_PnlRebatesInsightsHeaderComponent_Fragment
  | ComponentFields_PplHomeCardsComponent_Fragment
  | ComponentFields_PplHomeCategoriesDialsComponent_Fragment
  | ComponentFields_PplHomeDetailsChartComponent_Fragment
  | ComponentFields_PplHomeDetailsChartHeaderComponent_Fragment
  | ComponentFields_PplHomeDetailsDriversChartComponent_Fragment
  | ComponentFields_PplHomeDetailsDriversChartHeaderComponent_Fragment
  | ComponentFields_PplHomeDetailsDriversComponent_Fragment
  | ComponentFields_PplHomeDetailsRecommendationsComponent_Fragment
  | ComponentFields_PplHomeDialComponent_Fragment
  | ComponentFields_ProAgentDetailsComponent_Fragment
  | ComponentFields_ProDailyAgentComponent_Fragment
  | ComponentFields_ProHomeFeedComponent_Fragment
  | ComponentFields_RecommendationsActionsComponent_Fragment
  | ComponentFields_RecommendationsBreakdownComponent_Fragment
  | ComponentFields_RecommenderComponent_Fragment
  | ComponentFields_RndHomeDialsComponent_Fragment
  | ComponentFields_RndInsightsBusinessDevelopmentAndLicensingDriversComponent_Fragment
  | ComponentFields_RndInsightsBusinessDevelopmentAndLicensingTableComponent_Fragment
  | ComponentFields_RndInventoryRecommendationsBreakdownComponent_Fragment
  | ComponentFields_RndInventoryRecommendationsComponent_Fragment
  | ComponentFields_RndInventoryRootCausesComponent_Fragment
  | ComponentFields_RndStudyDesignFormComponent_Fragment
  | ComponentFields_RndStudyDesignsSiteDistributionTableComponent_Fragment
  | ComponentFields_RndStudyDesignsTableComponent_Fragment
  | ComponentFields_RndTrialsAiPosDriversComponent_Fragment
  | ComponentFields_RndTrialsKpiCategoriesComponent_Fragment
  | ComponentFields_RndTrialsKpiDetailTableComponent_Fragment
  | ComponentFields_RndTrialsRndInvestmentsPnlTableComponent_Fragment
  | ComponentFields_RndValueOverviewChartComponent_Fragment
  | ComponentFields_RndValueOverviewChartHeaderComponent_Fragment
  | ComponentFields_SpendCmoPulseDialComponent_Fragment
  | ComponentFields_SpendCmoPulseDriversChartComponent_Fragment
  | ComponentFields_SpendCmoPulseDriversListComponent_Fragment
  | ComponentFields_SpendCmoPulseKeyDriversChartComponent_Fragment
  | ComponentFields_SpendCmoPulseKeyDriversListComponent_Fragment
  | ComponentFields_SpendCmoPulseLeaderboardListComponent_Fragment
  | ComponentFields_SpendCmoPulseLeaderboardPositionComponent_Fragment
  | ComponentFields_SpendCmoPulseRecommendedActionsComponent_Fragment
  | ComponentFields_SpendGlocalPulseDialComponent_Fragment
  | ComponentFields_SpendGlocalPulseDriversChartComponent_Fragment
  | ComponentFields_SpendGlocalPulseDriversListComponent_Fragment
  | ComponentFields_SpendGlocalPulseKeyDriversChartComponent_Fragment
  | ComponentFields_SpendGlocalPulseKeyDriversListComponent_Fragment
  | ComponentFields_SpendGlocalPulseLeaderboardListComponent_Fragment
  | ComponentFields_SpendGlocalPulseLeaderboardPositionComponent_Fragment
  | ComponentFields_SpendGlocalPulseRecommendedActionsComponent_Fragment
  | ComponentFields_SpendHomeCardsComponent_Fragment
  | ComponentFields_SpendHomeDialComponent_Fragment
  | ComponentFields_SpendProcurementChartComponent_Fragment
  | ComponentFields_SpendProcurementTableComponent_Fragment
  | ComponentFields_SpendTrendingComponent_Fragment
  | ComponentFields_StoryListComponent_Fragment
  | ComponentFields_SummaryInfoComponent_Fragment
  | ComponentFields_SupplyHomeCardsComponent_Fragment
  | ComponentFields_SupplyHomeDialsComponent_Fragment
  | ComponentFields_SupplyLowInventoryRiskRootCausesComponent_Fragment
  | ComponentFields_SupplyPulseLeaderboardListComponent_Fragment
  | ComponentFields_SupplyShortDatedRootCausesComponent_Fragment
  | ComponentFields_SupportComponent_Fragment
  | ComponentFields_TableComponent_Fragment
  | ComponentFields_TableSwitcherComponent_Fragment
  | ComponentFields_TimelineComponent_Fragment
  | ComponentFields_UserAnalyticsComponent_Fragment
  | ComponentFields_UserPrioritiesComponent_Fragment
  | ComponentFields_VideoComponent_Fragment;

export type DataViewFields_ButtonDataView_Fragment = {
  __typename?: 'ButtonDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_CardsDataView_Fragment = {
  __typename?: 'CardsDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_CashFlowKpiDataViewResult_Fragment = {
  __typename?: 'CashFlowKpiDataViewResult';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_ChartDataViewResult_Fragment = {
  __typename?: 'ChartDataViewResult';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_CmoPulseLeaderboardPositionDataView_Fragment = {
  __typename?: 'CmoPulseLeaderboardPositionDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_CmoPulseRecommendedActionsDataView_Fragment = {
  __typename?: 'CmoPulseRecommendedActionsDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment>;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_DialsDataView_Fragment = {
  __typename?: 'DialsDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_EbiHomeCategoriesDialsDataView_Fragment = {
  __typename?: 'EbiHomeCategoriesDialsDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_EbiHomeDetailsChartHeaderDataView_Fragment = {
  __typename?: 'EbiHomeDetailsChartHeaderDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_EbiHomeDetailsDriverChartHeaderDataView_Fragment = {
  __typename?: 'EbiHomeDetailsDriverChartHeaderDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_EbiHomeDetailsDriversDataView_Fragment = {
  __typename?: 'EbiHomeDetailsDriversDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_EbiHomeDetailsRecommendationsDataView_Fragment = {
  __typename?: 'EbiHomeDetailsRecommendationsDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_EbiMicroChartResult_Fragment = {
  __typename?: 'EbiMicroChartResult';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_FinGtnOpexAiRecommendationDataView_Fragment = {
  __typename?: 'FinGtnOpexAiRecommendationDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_FinHomePageCardsDataView_Fragment = {
  __typename?: 'FinHomePageCardsDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_FinPnlAiRecommendationDataView_Fragment = {
  __typename?: 'FinPnlAiRecommendationDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_GlocalPulseLeaderboardPositionDataView_Fragment = {
  __typename?: 'GlocalPulseLeaderboardPositionDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_GlocalPulseRecommendedActionsDataView_Fragment = {
  __typename?: 'GlocalPulseRecommendedActionsDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment>;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_GraCardDataView_Fragment = {
  __typename?: 'GraCardDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_GraFacilitatedPathwaysTableDataView_Fragment = {
  __typename?: 'GraFacilitatedPathwaysTableDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment>;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_GraHomeDialsDataView_Fragment = {
  __typename?: 'GraHomeDialsDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_GraRecommendedPathwaysDetailsDataView_Fragment = {
  __typename?: 'GraRecommendedPathwaysDetailsDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment>;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_GraRecommendedPathwaysTableDataView_Fragment = {
  __typename?: 'GraRecommendedPathwaysTableDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment>;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_GridDataView_Fragment = {
  __typename?: 'GridDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_GtmCardDataView_Fragment = {
  __typename?: 'GtmCardDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_GtmHomeDialDataView_Fragment = {
  __typename?: 'GtmHomeDialDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_GtmHomepageCardsDataView_Fragment = {
  __typename?: 'GtmHomepageCardsDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_GtmImpactRecommendationDataView_Fragment = {
  __typename?: 'GtmImpactRecommendationDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_GtmImpactTableDataView_Fragment = {
  __typename?: 'GtmImpactTableDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_GtmImpactTableHeaderDataView_Fragment = {
  __typename?: 'GtmImpactTableHeaderDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_GtmInteractionsChartDataView_Fragment = {
  __typename?: 'GtmInteractionsChartDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_GtmInteractionsTableDataView_Fragment = {
  __typename?: 'GtmInteractionsTableDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_GtmPulseDetailsChartHeaderDataView_Fragment = {
  __typename?: 'GtmPulseDetailsChartHeaderDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_GtmPulseDetailsDriversDataView_Fragment = {
  __typename?: 'GtmPulseDetailsDriversDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_GtmPulseDialDataView_Fragment = {
  __typename?: 'GtmPulseDialDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_GtmPulseLeaderboardListDataView_Fragment = {
  __typename?: 'GtmPulseLeaderboardListDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_GtmPulseLeaderboardPositionDataView_Fragment = {
  __typename?: 'GtmPulseLeaderboardPositionDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_GtmPulseRecommendedActionsDataView_Fragment = {
  __typename?: 'GtmPulseRecommendedActionsDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_GtmReachTableDataView_Fragment = {
  __typename?: 'GtmReachTableDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_GtmResourceAllocationTableDataView_Fragment = {
  __typename?: 'GtmResourceAllocationTableDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_HeaderRow_Fragment = {
  __typename?: 'HeaderRow';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_HeaderSimple_Fragment = {
  __typename?: 'HeaderSimple';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_KpiStatusOverview_Fragment = {
  __typename?: 'KpiStatusOverview';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_ListDataView_Fragment = {
  __typename?: 'ListDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_MicroChartResult_Fragment = {
  __typename?: 'MicroChartResult';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_MnsDeviationsDetailsDataView_Fragment = {
  __typename?: 'MnsDeviationsDetailsDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_MnsDeviationsDialDataView_Fragment = {
  __typename?: 'MnsDeviationsDialDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_PnlRebatesBreakdownChartDataView_Fragment = {
  __typename?: 'PnlRebatesBreakdownChartDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_PnlRebatesBreakdownChartHeaderDataView_Fragment = {
  __typename?: 'PnlRebatesBreakdownChartHeaderDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_PnlRebatesBreakdownQuartersTableDataView_Fragment = {
  __typename?: 'PnlRebatesBreakdownQuartersTableDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_PnlRebatesBreakdownTableDataView_Fragment = {
  __typename?: 'PnlRebatesBreakdownTableDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_PnlRebatesInsightsCardsDataView_Fragment = {
  __typename?: 'PnlRebatesInsightsCardsDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_PnlRebatesInsightsHeaderDataView_Fragment = {
  __typename?: 'PnlRebatesInsightsHeaderDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_PplCardDataView_Fragment = {
  __typename?: 'PplCardDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_PplHomeCategoriesDialsDataView_Fragment = {
  __typename?: 'PplHomeCategoriesDialsDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_PplHomeDetailsChartHeaderDataView_Fragment = {
  __typename?: 'PplHomeDetailsChartHeaderDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_PplHomeDetailsDriverChartHeaderDataView_Fragment = {
  __typename?: 'PplHomeDetailsDriverChartHeaderDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_PplHomeDetailsDriversDataView_Fragment = {
  __typename?: 'PplHomeDetailsDriversDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_PplHomeDetailsRecommendationsDataView_Fragment = {
  __typename?: 'PplHomeDetailsRecommendationsDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_PplHomeDialDataView_Fragment = {
  __typename?: 'PplHomeDialDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_PplMicroChartResult_Fragment = {
  __typename?: 'PplMicroChartResult';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_ProAgentDetailsDataView_Fragment = {
  __typename?: 'ProAgentDetailsDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_ProDailyAgentDataView_Fragment = {
  __typename?: 'ProDailyAgentDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_ProHomeFeedDataView_Fragment = {
  __typename?: 'ProHomeFeedDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_RecommendationsActionsDataView_Fragment = {
  __typename?: 'RecommendationsActionsDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_RecommendationsBreakdownDataView_Fragment = {
  __typename?: 'RecommendationsBreakdownDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_RecommenderDataView_Fragment = {
  __typename?: 'RecommenderDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_RndHomeDialsDataView_Fragment = {
  __typename?: 'RndHomeDialsDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_RndInsightsBusinessDevelopmentAndLicensingDriversView_Fragment = {
  __typename?: 'RndInsightsBusinessDevelopmentAndLicensingDriversView';
  id: string;
  dataViewType: T.DataViewType;
  metadata: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment>;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_RndInsightsBusinessDevelopmentAndLicensingTableDataView_Fragment = {
  __typename?: 'RndInsightsBusinessDevelopmentAndLicensingTableDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment>;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_RndInventoryRecommendationsBreakdownDataView_Fragment = {
  __typename?: 'RndInventoryRecommendationsBreakdownDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_RndInventoryRecommendationsDataView_Fragment = {
  __typename?: 'RndInventoryRecommendationsDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_RndInventoryRootCausesDataView_Fragment = {
  __typename?: 'RndInventoryRootCausesDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_RndStudyDesignerSiteDistributionTableDataView_Fragment = {
  __typename?: 'RndStudyDesignerSiteDistributionTableDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment>;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_RndStudyDesignsTableDataView_Fragment = {
  __typename?: 'RndStudyDesignsTableDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment>;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_RndTimelineChartDataViewResult_Fragment = {
  __typename?: 'RndTimelineChartDataViewResult';
  id: string;
  dataViewType: T.DataViewType;
  metadata: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment>;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_RndTrialsAiPosDriversDataView_Fragment = {
  __typename?: 'RndTrialsAiPosDriversDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_RndTrialsBreakdownAiPtrsDriversDataView_Fragment = {
  __typename?: 'RndTrialsBreakdownAiPtrsDriversDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_RndTrialsKpiCategoriesDataView_Fragment = {
  __typename?: 'RndTrialsKpiCategoriesDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_RndTrialsKpiDetailTableDataView_Fragment = {
  __typename?: 'RndTrialsKpiDetailTableDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_RndTrialsRndInvestmentsPnlTableDataView_Fragment = {
  __typename?: 'RndTrialsRndInvestmentsPnlTableDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment>;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_RndTrialsValueOverviewChartDataView_Fragment = {
  __typename?: 'RndTrialsValueOverviewChartDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_RndTrialsValueOverviewChartDataViewResult_Fragment = {
  __typename?: 'RndTrialsValueOverviewChartDataViewResult';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_RndTrialsValueOverviewChartHeaderDataView_Fragment = {
  __typename?: 'RndTrialsValueOverviewChartHeaderDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_SpendCardDataView_Fragment = {
  __typename?: 'SpendCardDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_SpendCmoPulseDialDataView_Fragment = {
  __typename?: 'SpendCmoPulseDialDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_SpendCmoPulseDriversChartDataView_Fragment = {
  __typename?: 'SpendCmoPulseDriversChartDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment>;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_SpendCmoPulseDriversListDataView_Fragment = {
  __typename?: 'SpendCmoPulseDriversListDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment>;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_SpendCmoPulseLeaderboardListDataView_Fragment = {
  __typename?: 'SpendCmoPulseLeaderboardListDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment>;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_SpendGlocalPulseDialDataView_Fragment = {
  __typename?: 'SpendGlocalPulseDialDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_SpendGlocalPulseDriversChartDataView_Fragment = {
  __typename?: 'SpendGlocalPulseDriversChartDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment>;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_SpendGlocalPulseDriversListDataView_Fragment = {
  __typename?: 'SpendGlocalPulseDriversListDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment>;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_SpendGlocalPulseLeaderboardListDataView_Fragment = {
  __typename?: 'SpendGlocalPulseLeaderboardListDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment>;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_SpendHomeDialDataView_Fragment = {
  __typename?: 'SpendHomeDialDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_SpendProcurementChartDataView_Fragment = {
  __typename?: 'SpendProcurementChartDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_SpendProcurementTableDataView_Fragment = {
  __typename?: 'SpendProcurementTableDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_SpendTrendingResult_Fragment = {
  __typename?: 'SpendTrendingResult';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_SummaryInfoDataView_Fragment = {
  __typename?: 'SummaryInfoDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_SupplyHomeCardsDataView_Fragment = {
  __typename?: 'SupplyHomeCardsDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_SupplyHomeDialsDataView_Fragment = {
  __typename?: 'SupplyHomeDialsDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_SupplyLowInventoryRiskRootCausesDataView_Fragment = {
  __typename?: 'SupplyLowInventoryRiskRootCausesDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_SupplyPulseDriverKpisDataView_Fragment = {
  __typename?: 'SupplyPulseDriverKpisDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_SupplyPulseLandingResult_Fragment = {
  __typename?: 'SupplyPulseLandingResult';
  id: string;
  dataViewType: T.DataViewType;
  metadata: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment>;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_SupplyPulseLeaderboardListDataView_Fragment = {
  __typename?: 'SupplyPulseLeaderboardListDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_SupplyShortDatedRootCausesDataView_Fragment = {
  __typename?: 'SupplyShortDatedRootCausesDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFields_TableDataView_Fragment = {
  __typename?: 'TableDataView';
  id: string;
  dataViewType: T.DataViewType;
  metadata?: Array<{ __typename?: 'MetaData' } & MetaDataFieldsFragment> | null;
  availableUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type DataViewFieldsFragment =
  | DataViewFields_ButtonDataView_Fragment
  | DataViewFields_CardsDataView_Fragment
  | DataViewFields_CashFlowKpiDataViewResult_Fragment
  | DataViewFields_ChartDataViewResult_Fragment
  | DataViewFields_CmoPulseLeaderboardPositionDataView_Fragment
  | DataViewFields_CmoPulseRecommendedActionsDataView_Fragment
  | DataViewFields_DialsDataView_Fragment
  | DataViewFields_EbiHomeCategoriesDialsDataView_Fragment
  | DataViewFields_EbiHomeDetailsChartHeaderDataView_Fragment
  | DataViewFields_EbiHomeDetailsDriverChartHeaderDataView_Fragment
  | DataViewFields_EbiHomeDetailsDriversDataView_Fragment
  | DataViewFields_EbiHomeDetailsRecommendationsDataView_Fragment
  | DataViewFields_EbiMicroChartResult_Fragment
  | DataViewFields_FinGtnOpexAiRecommendationDataView_Fragment
  | DataViewFields_FinHomePageCardsDataView_Fragment
  | DataViewFields_FinPnlAiRecommendationDataView_Fragment
  | DataViewFields_GlocalPulseLeaderboardPositionDataView_Fragment
  | DataViewFields_GlocalPulseRecommendedActionsDataView_Fragment
  | DataViewFields_GraCardDataView_Fragment
  | DataViewFields_GraFacilitatedPathwaysTableDataView_Fragment
  | DataViewFields_GraHomeDialsDataView_Fragment
  | DataViewFields_GraRecommendedPathwaysDetailsDataView_Fragment
  | DataViewFields_GraRecommendedPathwaysTableDataView_Fragment
  | DataViewFields_GridDataView_Fragment
  | DataViewFields_GtmCardDataView_Fragment
  | DataViewFields_GtmHomeDialDataView_Fragment
  | DataViewFields_GtmHomepageCardsDataView_Fragment
  | DataViewFields_GtmImpactRecommendationDataView_Fragment
  | DataViewFields_GtmImpactTableDataView_Fragment
  | DataViewFields_GtmImpactTableHeaderDataView_Fragment
  | DataViewFields_GtmInteractionsChartDataView_Fragment
  | DataViewFields_GtmInteractionsTableDataView_Fragment
  | DataViewFields_GtmPulseDetailsChartHeaderDataView_Fragment
  | DataViewFields_GtmPulseDetailsDriversDataView_Fragment
  | DataViewFields_GtmPulseDialDataView_Fragment
  | DataViewFields_GtmPulseLeaderboardListDataView_Fragment
  | DataViewFields_GtmPulseLeaderboardPositionDataView_Fragment
  | DataViewFields_GtmPulseRecommendedActionsDataView_Fragment
  | DataViewFields_GtmReachTableDataView_Fragment
  | DataViewFields_GtmResourceAllocationTableDataView_Fragment
  | DataViewFields_HeaderRow_Fragment
  | DataViewFields_HeaderSimple_Fragment
  | DataViewFields_KpiStatusOverview_Fragment
  | DataViewFields_ListDataView_Fragment
  | DataViewFields_MicroChartResult_Fragment
  | DataViewFields_MnsDeviationsDetailsDataView_Fragment
  | DataViewFields_MnsDeviationsDialDataView_Fragment
  | DataViewFields_PnlRebatesBreakdownChartDataView_Fragment
  | DataViewFields_PnlRebatesBreakdownChartHeaderDataView_Fragment
  | DataViewFields_PnlRebatesBreakdownQuartersTableDataView_Fragment
  | DataViewFields_PnlRebatesBreakdownTableDataView_Fragment
  | DataViewFields_PnlRebatesInsightsCardsDataView_Fragment
  | DataViewFields_PnlRebatesInsightsHeaderDataView_Fragment
  | DataViewFields_PplCardDataView_Fragment
  | DataViewFields_PplHomeCategoriesDialsDataView_Fragment
  | DataViewFields_PplHomeDetailsChartHeaderDataView_Fragment
  | DataViewFields_PplHomeDetailsDriverChartHeaderDataView_Fragment
  | DataViewFields_PplHomeDetailsDriversDataView_Fragment
  | DataViewFields_PplHomeDetailsRecommendationsDataView_Fragment
  | DataViewFields_PplHomeDialDataView_Fragment
  | DataViewFields_PplMicroChartResult_Fragment
  | DataViewFields_ProAgentDetailsDataView_Fragment
  | DataViewFields_ProDailyAgentDataView_Fragment
  | DataViewFields_ProHomeFeedDataView_Fragment
  | DataViewFields_RecommendationsActionsDataView_Fragment
  | DataViewFields_RecommendationsBreakdownDataView_Fragment
  | DataViewFields_RecommenderDataView_Fragment
  | DataViewFields_RndHomeDialsDataView_Fragment
  | DataViewFields_RndInsightsBusinessDevelopmentAndLicensingDriversView_Fragment
  | DataViewFields_RndInsightsBusinessDevelopmentAndLicensingTableDataView_Fragment
  | DataViewFields_RndInventoryRecommendationsBreakdownDataView_Fragment
  | DataViewFields_RndInventoryRecommendationsDataView_Fragment
  | DataViewFields_RndInventoryRootCausesDataView_Fragment
  | DataViewFields_RndStudyDesignerSiteDistributionTableDataView_Fragment
  | DataViewFields_RndStudyDesignsTableDataView_Fragment
  | DataViewFields_RndTimelineChartDataViewResult_Fragment
  | DataViewFields_RndTrialsAiPosDriversDataView_Fragment
  | DataViewFields_RndTrialsBreakdownAiPtrsDriversDataView_Fragment
  | DataViewFields_RndTrialsKpiCategoriesDataView_Fragment
  | DataViewFields_RndTrialsKpiDetailTableDataView_Fragment
  | DataViewFields_RndTrialsRndInvestmentsPnlTableDataView_Fragment
  | DataViewFields_RndTrialsValueOverviewChartDataView_Fragment
  | DataViewFields_RndTrialsValueOverviewChartDataViewResult_Fragment
  | DataViewFields_RndTrialsValueOverviewChartHeaderDataView_Fragment
  | DataViewFields_SpendCardDataView_Fragment
  | DataViewFields_SpendCmoPulseDialDataView_Fragment
  | DataViewFields_SpendCmoPulseDriversChartDataView_Fragment
  | DataViewFields_SpendCmoPulseDriversListDataView_Fragment
  | DataViewFields_SpendCmoPulseLeaderboardListDataView_Fragment
  | DataViewFields_SpendGlocalPulseDialDataView_Fragment
  | DataViewFields_SpendGlocalPulseDriversChartDataView_Fragment
  | DataViewFields_SpendGlocalPulseDriversListDataView_Fragment
  | DataViewFields_SpendGlocalPulseLeaderboardListDataView_Fragment
  | DataViewFields_SpendHomeDialDataView_Fragment
  | DataViewFields_SpendProcurementChartDataView_Fragment
  | DataViewFields_SpendProcurementTableDataView_Fragment
  | DataViewFields_SpendTrendingResult_Fragment
  | DataViewFields_SummaryInfoDataView_Fragment
  | DataViewFields_SupplyHomeCardsDataView_Fragment
  | DataViewFields_SupplyHomeDialsDataView_Fragment
  | DataViewFields_SupplyLowInventoryRiskRootCausesDataView_Fragment
  | DataViewFields_SupplyPulseDriverKpisDataView_Fragment
  | DataViewFields_SupplyPulseLandingResult_Fragment
  | DataViewFields_SupplyPulseLeaderboardListDataView_Fragment
  | DataViewFields_SupplyShortDatedRootCausesDataView_Fragment
  | DataViewFields_TableDataView_Fragment;

export type DialItemFieldsFragment = {
  __typename?: 'DialItem';
  label: string;
  sentiment: T.Color;
  progress: number;
  target?: number | null;
  progressText?: { __typename?: 'TextResult'; value?: string | null } | null;
  targetText?: { __typename?: 'TextResult'; value?: string | null } | null;
  actualText?: { __typename?: 'TextResult'; value?: string | null } | null;
  varianceText?: ({ __typename?: 'TextResult' } & TextFieldsFragment) | null;
  varianceAIText?: ({ __typename?: 'TextResult' } & TextFieldsFragment) | null;
  link?: ({ __typename?: 'TextLinkResult' } & TextLinkFieldsFragment) | null;
};

export type FilterComponentFieldsFragment = {
  __typename?: 'FilterComponent';
  defaultValue?: number | null;
  alignment?: T.Alignment | null;
  isHidden?: boolean | null;
  displayMode?: T.DisplayMode | null;
  filterSynchronizationGroup?: string | null;
} & ComponentFields_FilterComponent_Fragment;

export type FilterFields_SelectFilter_Fragment = {
  __typename?: 'SelectFilter';
  id: string;
  label?: string | null;
  filterType: T.FilterType;
};

export type FilterFields_SelectGroupFilter_Fragment = {
  __typename?: 'SelectGroupFilter';
  id: string;
  label?: string | null;
  filterType: T.FilterType;
};

export type FilterFields_TreeSelectFilter_Fragment = {
  __typename?: 'TreeSelectFilter';
  id: string;
  label?: string | null;
  filterType: T.FilterType;
};

export type FilterFieldsFragment =
  | FilterFields_SelectFilter_Fragment
  | FilterFields_SelectGroupFilter_Fragment
  | FilterFields_TreeSelectFilter_Fragment;

export type FilterOptionFieldsFragment = {
  __typename?: 'FilterOptionResult';
  id: string;
  label: string;
  value?: number | null;
  isDefault?: boolean | null;
  isTarget?: boolean | null;
  additionalLabels?: Array<string> | null;
  sentiment?: T.Sentiment | null;
};

export type FilterValueFields_MultiSelectFilterValue_Fragment = {
  __typename?: 'MultiSelectFilterValue';
  id: string;
  selectedValues: Array<number | null>;
};

export type FilterValueFields_SelectFilterValue_Fragment = {
  __typename?: 'SelectFilterValue';
  id: string;
  value: number;
};

export type FilterValueFields_SelectGroupFilterValue_Fragment = {
  __typename?: 'SelectGroupFilterValue';
  id: string;
  values: Array<{ __typename?: 'FilterCodeValue'; filterCode: string; value: number } | null>;
};

export type FilterValueFieldsFragment =
  | FilterValueFields_MultiSelectFilterValue_Fragment
  | FilterValueFields_SelectFilterValue_Fragment
  | FilterValueFields_SelectGroupFilterValue_Fragment;

export type FormattedValueFieldsFragment = {
  __typename?: 'FormattedValue';
  raw?: number | null;
  formatted?: string | null;
};

export type InterceptFieldsFragment = {
  __typename?: 'InterceptResult';
  id: string;
  name?: string | null;
  title?: string | null;
  description?: string | null;
  linkText?: string | null;
  cancelText?: string | null;
  link?: ({ __typename?: 'Link' } & LinkFieldsFragment) | null;
  media?: {
    __typename?: 'ImageResult';
    imageUrl?: string | null;
    id: string;
    title: string;
    thumbnailUrl: string;
  } | null;
};

export type KpiCardFieldsFragment = {
  __typename?: 'KpiCardResult';
  id: string;
  code: string;
  cardType: T.CardType;
  title: { __typename?: 'TextResult' } & TextFieldsFragment;
  items?: Array<{ __typename?: 'KpiStatus' } & KpiStatusFieldsFragment> | null;
  linkResult?: ({ __typename?: 'Link' } & LinkFieldsFragment) | null;
};

export type KpiStatusFieldsFragment = {
  __typename?: 'KpiStatus';
  actual: string;
  target?: string | null;
  sentiment: T.Sentiment;
  icon: T.Icon;
  iconLink?: {
    __typename?: 'IconLinkResult';
    icon?: T.Icon | null;
    linkResult?: ({ __typename?: 'Link' } & LinkFieldsFragment) | null;
  } | null;
};

export type KpiStatusOverviewFieldsFragment = {
  __typename?: 'KpiStatusOverview';
  items?: Array<{ __typename?: 'KpiStatus' } & KpiStatusFieldsFragment> | null;
  tooltip: {
    __typename?: 'TableContent';
    title: string;
    columns?: Array<{ __typename?: 'TableColumnResult' } & TableColumnFieldsFragment> | null;
    rows?: Array<{
      __typename?: 'TableRowResult';
      rowId: number;
      rowType: T.RowType;
      cells?: Array<{
        __typename?: 'TableCellResult';
        columnDataKey: string;
        cellContent?:
          | { __typename?: 'KpiStatus'; text?: string | null; icon: T.Icon; sentiment: T.Sentiment }
          | { __typename?: 'TextResult'; isHighlighted?: boolean | null; value?: string | null }
          | null;
      }> | null;
    }> | null;
  };
} & DataViewFields_KpiStatusOverview_Fragment;

export type LinkFieldsFragment = {
  __typename?: 'Link';
  pageId?: string | null;
  absolutePath?: string | null;
  drillId?: number | null;
  drillIds?: Array<number> | null;
  target?: T.LinkTargetType | null;
  plailistId?: string | null;
  previousPriorityId?: number | null;
  filters?: Array<
    | ({
        __typename?: 'MultiSelectFilterValue';
      } & FilterValueFields_MultiSelectFilterValue_Fragment)
    | ({ __typename?: 'SelectFilterValue' } & FilterValueFields_SelectFilterValue_Fragment)
    | ({
        __typename?: 'SelectGroupFilterValue';
      } & FilterValueFields_SelectGroupFilterValue_Fragment)
  > | null;
};

export type MediaFields_ImageResult_Fragment = {
  __typename?: 'ImageResult';
  id: string;
  title: string;
  thumbnailUrl: string;
};

export type MediaFields_VideoContent_Fragment = {
  __typename?: 'VideoContent';
  id: string;
  title: string;
  thumbnailUrl: string;
};

export type MediaFieldsFragment =
  | MediaFields_ImageResult_Fragment
  | MediaFields_VideoContent_Fragment;

export type MetaDataFieldsFragment = {
  __typename?: 'MetaData';
  key?: string | null;
  label?: string | null;
  value?: string | null;
};

export type MetricFieldsFragment = {
  __typename?: 'Metric';
  id: number;
  name: string;
  value: string;
  sentiment?: T.Sentiment | null;
  variances?: Array<({ __typename?: 'VarianceResult' } & VarianceFieldsFragment) | null> | null;
};

export type MetricListFieldsFragment = {
  __typename?: 'MetricList';
  name: string;
  metrics: Array<({ __typename?: 'Metric' } & MetricFieldsFragment) | null>;
};

export type MicroChartCardFieldsFragment = {
  __typename?: 'MicroChartCardResult';
  id: string;
  code: string;
  cardType: T.CardType;
  title: { __typename?: 'TextResult' } & TextFieldsFragment;
  actual?: ({ __typename?: 'TextResult' } & TextFieldsFragment) | null;
  variance?: ({ __typename?: 'VarianceResult' } & VarianceFieldsFragment) | null;
  microChart?: ({ __typename?: 'MicroChartResult' } & MicroChartFieldsFragment) | null;
  linkResult?: ({ __typename?: 'Link' } & LinkFieldsFragment) | null;
};

export type MicroChartFieldsFragment = {
  __typename?: 'MicroChartResult';
  seriesData?: Array<number | null> | null;
  trendlineData?: Array<number | null> | null;
  sentiment?: T.Sentiment | null;
};

export type ModuleFieldsFragment = {
  __typename?: 'Module';
  id: string;
  name: string;
  moduleCode: T.ModuleCode;
  path: string;
  pages?: Array<
    {
      __typename?: 'Page';
      children?: Array<
        {
          __typename?: 'Page';
          children?: Array<
            {
              __typename?: 'Page';
              children?: Array<
                {
                  __typename?: 'Page';
                  children?: Array<{ __typename?: 'Page' } & PageFieldsFragment> | null;
                } & PageFieldsFragment
              > | null;
            } & PageFieldsFragment
          > | null;
        } & PageFieldsFragment
      > | null;
    } & PageFieldsFragment
  > | null;
};

export type NavigationTabsComponentFieldsFragment = {
  __typename?: 'NavigationTabsComponent';
  tabs?: Array<{ __typename?: 'Tab' } & TabFieldsFragment> | null;
} & ComponentFields_NavigationTabsComponent_Fragment;

export type PageFieldsFragment = {
  __typename?: 'Page';
  id: string;
  title: string;
  pageType: T.PageTypeEnum;
  menuTitle?: string | null;
  menuIcon?: T.MenuIcon | null;
  path: string;
  absolutePath: string;
  isMenuHidden?: boolean | null;
};

export type PlailistsResultFieldsFragment = {
  __typename?: 'PlailistsResult';
  categories: Array<({ __typename?: 'PlailistCategory' } & PlailistCategoryFieldsFragment) | null>;
  metadata?: Array<({ __typename?: 'MetaData' } & MetaDataFieldsFragment) | null> | null;
};

export type PlailistFieldsFragment = {
  __typename?: 'Plailist';
  plailistId: string;
  title?: string | null;
  summary?: string | null;
  filters?: Array<({ __typename: 'PlailistFilter' } & PlailistFilterFieldsFragment) | null> | null;
  colorStart?: { __typename?: 'ColorString'; color?: string | null } | null;
  colorEnd?: { __typename?: 'ColorString'; color?: string | null } | null;
  stories?: Array<({ __typename?: 'Story' } & StoryFieldsFragment) | null> | null;
};

export type PlailistCategoryFieldsFragment = {
  __typename?: 'PlailistCategory';
  categoryId: string;
  name: string;
  plailists?: Array<({ __typename?: 'Plailist' } & PlailistFieldsFragment) | null> | null;
};

export type PlailistFilterFieldsFragment = {
  __typename?: 'PlailistFilter';
  filterCode: string;
  options?: Array<
    | ({
        __typename?: 'PlailistFilterOption';
        children?: Array<
          {
            __typename?: 'PlailistFilterOption';
            children?: Array<
              {
                __typename?: 'PlailistFilterOption';
                children?: Array<
                  {
                    __typename?: 'PlailistFilterOption';
                    children?: Array<
                      { __typename?: 'PlailistFilterOption' } & PlailistFilterOptionFieldsFragment
                    > | null;
                  } & PlailistFilterOptionFieldsFragment
                > | null;
              } & PlailistFilterOptionFieldsFragment
            > | null;
          } & PlailistFilterOptionFieldsFragment
        > | null;
      } & PlailistFilterOptionFieldsFragment)
    | null
  > | null;
};

export type PlailistFilterOptionFieldsFragment = {
  __typename?: 'PlailistFilterOption';
  id: number;
  value?: number | null;
  label: string;
  isDefault?: boolean | null;
};

export type PriorityCategoryFieldsFragment = {
  __typename?: 'PriorityCategoryResult';
  categoryName: string;
  priorities: Array<{ __typename?: 'PriorityResult' } & PriorityFieldsFragment>;
};

export type PriorityFieldsFragment = {
  __typename?: 'PriorityResult';
  priorityId: number;
  priorityName: string;
  isFollowed: boolean;
  sortOrder: number;
  subLabel?: string | null;
};

export type ProgressBarFieldsFragment = {
  __typename?: 'ProgressBarResult';
  progressValue: number;
  baseValue: number;
  min: number;
  max: number;
  sentiment?: T.Sentiment | null;
  inverted: boolean;
  variant?: T.ProgressBarVariant | null;
};

export type RecommendationsListFieldsFragment = {
  __typename?: 'RecommendationsList';
  title: string;
  label?: string | null;
  sentiment?: T.Sentiment | null;
  items?: Array<{ __typename?: 'RecommendationItem' } & RecommendationItemFieldsFragment> | null;
  link?: ({ __typename?: 'Link' } & LinkFieldsFragment) | null;
};

export type RecommendationsBreakdownFieldsFragment = {
  __typename?: 'RecommendationsBreakdown';
  dataViewId: string;
  viewCode?: string | null;
  title: string;
};

export type RecommendationsActionsDataViewFieldsFragment = {
  __typename?: 'RecommendationsActionsDataView';
  pageHeader?: string | null;
  pageSubheader?: string | null;
  recommendationsList: Array<
    ({ __typename?: 'RecommendationsList' } & RecommendationsListFieldsFragment) | null
  >;
} & DataViewFields_RecommendationsActionsDataView_Fragment;

export type RecommendationsBreakdownDataViewFieldsFragment = {
  __typename?: 'RecommendationsBreakdownDataView';
  breakdowns?: Array<
    { __typename?: 'RecommendationsBreakdown' } & RecommendationsBreakdownFieldsFragment
  > | null;
} & DataViewFields_RecommendationsBreakdownDataView_Fragment;

export type RecommendationsActionsComponentFieldsFragment = {
  __typename?: 'RecommendationsActionsComponent';
  id: string;
  componentType: T.ComponentType;
  viewType: T.ViewType;
  code: string;
  icon: T.ComponentIcon;
  filters?: Array<{ __typename?: 'FilterComponent' } & FilterComponentFieldsFragment> | null;
};

export type RecommendationsBreakdownComponentFieldsFragment = {
  __typename?: 'RecommendationsBreakdownComponent';
  id: string;
  componentType: T.ComponentType;
  viewType: T.ViewType;
  code: string;
  icon: T.ComponentIcon;
  filters?: Array<{ __typename?: 'FilterComponent' } & FilterComponentFieldsFragment> | null;
};

export type RecommendationItemFieldsFragment = {
  __typename: 'RecommendationItem';
  title: string;
  description?: string | null;
  cost?: string | null;
  icon?: T.RecommendationItemIcon | null;
  sortOrder?: number | null;
  value?: string | null;
  details?: Array<{
    __typename: 'RecommendationItemDetail';
    cost?: string | null;
    id: number;
    title: string;
    value: string;
    isPinned: boolean;
    whatIfDeepLink?: ({ __typename?: 'TextLinkResult' } & TextLinkFieldsFragment) | null;
    itemsContent?: Array<{
      __typename?: 'RecommendationItemContent';
      icon: T.RecommendationItemContentIcon;
      title: string;
    } | null> | null;
  } | null> | null;
};

export type RecommenderDataViewFieldsFragment = {
  __typename?: 'RecommenderDataView';
  insights?: Array<
    | ({ __typename?: 'Real' } & RealFieldsFragment)
    | ({ __typename?: 'Story' } & StoryFieldsFragment)
  > | null;
  seeAllLink?: ({ __typename?: 'Link' } & LinkFieldsFragment) | null;
} & DataViewFields_RecommenderDataView_Fragment;

export type ReferenceFieldsFragment = {
  __typename?: 'ExternalLink';
  displayText?: string | null;
  url?: string | null;
};

export type SelectFilterFieldsFragment = {
  __typename?: 'SelectFilter';
  filterCode?: string | null;
  additionalLabels: Array<string | null>;
  options?: Array<{ __typename?: 'FilterOptionResult' } & FilterOptionFieldsFragment> | null;
};

export type SelectGroupFilterFieldsFragment = {
  __typename?: 'SelectGroupFilter';
  filterCode?: string | null;
  additionalLabels: Array<string | null>;
  filters: Array<{
    __typename?: 'SelectFilter';
    id: string;
    additionalLabels: Array<string | null>;
    filterCode?: string | null;
    label?: string | null;
    options?: Array<{ __typename?: 'FilterOptionResult' } & FilterOptionFieldsFragment> | null;
  }>;
};

export type SimpleCardFieldsFragment = {
  __typename?: 'SimpleCardResult';
  id: string;
  code: string;
  cardType: T.CardType;
  title: { __typename?: 'TextResult' } & TextFieldsFragment;
  textResult?: ({ __typename?: 'TextResult' } & TextFieldsFragment) | null;
  linkResult?: ({ __typename?: 'Link' } & LinkFieldsFragment) | null;
  variance?: ({ __typename?: 'VarianceResult' } & VarianceFieldsFragment) | null;
};

export type RealFieldsFragment = {
  __typename: 'Real';
  id: string;
  title: string;
  insightsType: T.InsightsType;
  hasSeenAllScreens: boolean;
  isRead: boolean;
  isShareable: boolean;
  header: {
    __typename?: 'RealHeader';
    title: string;
    subtitle?: string | null;
    icon?: T.RealIcon | null;
  };
  screens: Array<{
    __typename: 'RealScreen';
    screenId: string;
    screenType: T.RealScreenType;
    header?: {
      __typename?: 'RealScreenHeader';
      title: string;
      subtitle?: string | null;
      icon: T.RealIcon;
    } | null;
    audio?: { __typename?: 'RealAudio'; trackName: string } | null;
    seeMoreLink?: {
      __typename?: 'TextLinkResult';
      subLabels?: Array<string> | null;
      value?: string | null;
      label?: string | null;
      linkDirection?: T.LinkDirection | null;
      linkResult?:
        | ({
            __typename: 'Link';
            pageId?: string | null;
            absolutePath?: string | null;
          } & LinkFieldsFragment)
        | null;
    } | null;
    content?: {
      __typename: 'RealTextList';
      items?: Array<{
        __typename?: 'RealTextListItem';
        icon?: T.RealIcon | null;
        text: Array<{
          __typename?: 'FormattedTextResult';
          content: string;
          sentiment?: T.Sentiment | null;
          size?: T.TextSize | null;
        } | null>;
      } | null> | null;
    } | null;
  } | null>;
};

export type StoryFieldsFragment = {
  __typename?: 'Story';
  id: string;
  insightsType: T.InsightsType;
  storyType: T.StoryDisplayType;
  headline: string;
  recommenderHeadline: string;
  storyDataModule: T.ModuleCode;
  caption?: string | null;
  isAddedToBrain?: boolean | null;
  brainsCount: number;
  formattedValue?: string | null;
  module: T.ModuleCode;
  sentiment?: T.Sentiment | null;
  publishDate: string;
  isRead: boolean;
  tag?: string | null;
  stamp?: string | null;
  hasFeedback: boolean;
  isLiked?: boolean | null;
  timeStamp?: string | null;
  decision?: {
    __typename?: 'StoryDecision';
    isTaken: boolean;
    screenIds: Array<number | null>;
    title: string;
    subTitle: string;
  } | null;
  content?: Array<
    | ({
        __typename?: 'ChartComponent';
        drillIds?: Array<number> | null;
        filters?: Array<{ __typename?: 'FilterComponent' } & FilterComponentFieldsFragment> | null;
      } & ComponentFields_ChartComponent_Fragment)
    | ({
        __typename?: 'HeaderRowComponent';
        drillIds?: Array<number> | null;
        variant: T.DisplayVariant;
        filters?: Array<{ __typename?: 'FilterComponent' } & FilterComponentFieldsFragment> | null;
      } & ComponentFields_HeaderRowComponent_Fragment)
    | ({
        __typename?: 'MarkdownComponent';
        content: string;
      } & ComponentFields_MarkdownComponent_Fragment)
    | ({ __typename?: 'TableComponent' } & TableComponentFieldsFragment)
    | ({
        __typename?: 'VideoComponent';
        videoContent: {
          __typename?: 'VideoContent';
          id: string;
          thumbnailUrl: string;
          lqipUrl?: string | null;
          sourceUrl: string;
        };
      } & ComponentFields_VideoComponent_Fragment)
    | null
  > | null;
  link?: ({ __typename?: 'Link' } & LinkFieldsFragment) | null;
  legacyLink?: {
    __typename?: 'LegacyLink';
    focusAreaCode?: string | null;
    moduleCode?: string | null;
    pageCode?: string | null;
    path?: string | null;
    queryParams?: string | null;
    viewCode?: string | null;
    visibleColumns?: Array<string | null> | null;
    filters?: Array<{
      __typename?: 'LegacyLinkFilter';
      filterCode?: string | null;
      filterLabel?: string | null;
      filterStringValue?: string | null;
      filterValue?: number | null;
    } | null> | null;
  } | null;
  reference?: ({ __typename?: 'ExternalLink' } & ReferenceFieldsFragment) | null;
};

export type SummaryInfoItemFieldsFragment = {
  __typename?: 'SummaryInfoItem';
  header:
    | ({ __typename?: 'TextLinkResult' } & TextLinkFieldsFragment)
    | { __typename?: 'TextResult'; value?: string | null };
  content: { __typename?: 'TextResult'; value?: string | null };
};

export type TabFieldsFragment = {
  __typename?: 'Tab';
  title?: string | null;
  path?: string | null;
  pageId?: string | null;
};

export type TableCellContentFields_BadgeResult_Fragment = {
  __typename?: 'BadgeResult';
  content: string;
  sentiment?: T.Sentiment | null;
};

export type TableCellContentFields_ColoredVarianceResult_Fragment = {
  __typename: 'ColoredVarianceResult';
  actual?: string | null;
  label?: string | null;
  sentiment?: T.Sentiment | null;
  subLabels?: Array<string> | null;
  target?: string | null;
};

export type TableCellContentFields_ColoredVarianceWithDeltaResult_Fragment = {
  __typename?: 'ColoredVarianceWithDeltaResult';
  actual?: string | null;
  sentiment?: T.Sentiment | null;
  deltaValues?: {
    __typename?: 'Delta';
    actual?: string | null;
    sentiment?: T.Sentiment | null;
  } | null;
};

export type TableCellContentFields_ComparatorTrendResult_Fragment = {
  __typename?: 'ComparatorTrendResult';
  rightValue?: string | null;
  leftValue: {
    __typename?: 'ValueTextResult';
    value?: string | null;
    sentiment?: T.Sentiment | null;
  };
  trendArrow?: {
    __typename?: 'TrendArrowResult';
    direction: T.Direction;
    sentiment: T.Sentiment;
  } | null;
};

export type TableCellContentFields_CriteriaBadgeResult_Fragment = {
  __typename?: 'CriteriaBadgeResult';
};

export type TableCellContentFields_DrillDownResult_Fragment = {
  __typename?: 'DrillDownResult';
  value?: string | null;
  subLabels?: Array<string> | null;
  drillUpId?: number | null;
  drillDownId?: number | null;
  drillUpIds?: Array<number> | null;
  drillDownIds?: Array<number | null> | null;
  dimensionIds?: Array<number | null> | null;
};

export type TableCellContentFields_GraphicElementResult_Fragment = {
  __typename?: 'GraphicElementResult';
};

export type TableCellContentFields_IconLinkResult_Fragment = {
  __typename?: 'IconLinkResult';
  icon?: T.Icon | null;
  showIcon?: boolean | null;
  linkResult?: ({ __typename?: 'Link' } & LinkFieldsFragment) | null;
};

export type TableCellContentFields_IconsLinkResult_Fragment = {
  __typename?: 'IconsLinkResult';
  linkResult?: ({ __typename?: 'Link' } & LinkFieldsFragment) | null;
} & IconsLinkFieldsFragment;

export type TableCellContentFields_KpiStatus_Fragment = { __typename?: 'KpiStatus' };

export type TableCellContentFields_KpiStatusOverview_Fragment = {
  __typename?: 'KpiStatusOverview';
} & KpiStatusOverviewFieldsFragment;

export type TableCellContentFields_LocationMarkerResult_Fragment = {
  __typename?: 'LocationMarkerResult';
  text?: string | null;
  position?: {
    __typename?: 'GeolocationPositionResult';
    coordinates?: {
      __typename?: 'GeolocationCoordinatesResult';
      latitude?: number | null;
      longitude?: number | null;
    } | null;
  } | null;
};

export type TableCellContentFields_MicroChartResult_Fragment = {
  __typename?: 'MicroChartResult';
  linkResult?: ({ __typename?: 'Link' } & LinkFieldsFragment) | null;
} & MicroChartFieldsFragment;

export type TableCellContentFields_ProgressBarResult_Fragment = {
  __typename?: 'ProgressBarResult';
} & ProgressBarFieldsFragment;

export type TableCellContentFields_RegionCountryResult_Fragment = {
  __typename?: 'RegionCountryResult';
  regionCode: string;
  regionName: string;
  countryCode?: string | null;
  countryName?: string | null;
};

export type TableCellContentFields_RiskIndicatorResult_Fragment = {
  __typename?: 'RiskIndicatorResult';
  actual?: string | null;
  previousSentiment?: T.Sentiment | null;
  sentiment?: T.Sentiment | null;
  target?: string | null;
};

export type TableCellContentFields_StatusTextResult_Fragment = { __typename?: 'StatusTextResult' };

export type TableCellContentFields_StudyDesignLinkResult_Fragment = {
  __typename?: 'StudyDesignLinkResult';
};

export type TableCellContentFields_TextExtendedResult_Fragment = {
  __typename?: 'TextExtendedResult';
};

export type TableCellContentFields_TextLinkResult_Fragment = {
  __typename?: 'TextLinkResult';
} & TextLinkFieldsFragment;

export type TableCellContentFields_TextResult_Fragment = {
  __typename?: 'TextResult';
  value?: string | null;
  subLabels?: Array<string> | null;
  isHighlighted?: boolean | null;
};

export type TableCellContentFields_Timeline_Fragment = {
  __typename?: 'Timeline';
  axis: { __typename?: 'TimelineAxis' } & TimelineAxisFieldsFragment;
  intervals?: Array<{ __typename?: 'TimelineInterval' } & TimelineIntervalFieldsFragment> | null;
  series?: Array<{
    __typename?: 'TimelineSeries';
    id: string;
    name: string;
    sortOrder?: number | null;
    marker: {
      __typename?: 'TimelineMarker';
      symbol: T.TimelineMarkerSymbol;
      isFilled: boolean;
      color: { __typename?: 'ThemeColor'; color?: T.Color | null };
    };
    data?: Array<{
      __typename?: 'TimelineDataPoint';
      x: number;
      formattedX: string;
      label: string;
      marker?: {
        __typename?: 'TimelineMarker';
        symbol: T.TimelineMarkerSymbol;
        isFilled: boolean;
        color: { __typename?: 'ThemeColor'; color?: T.Color | null };
      } | null;
      tooltip?: {
        __typename?: 'TableContent';
        title: string;
        columns?: Array<{ __typename?: 'TableColumnResult' } & TableColumnFieldsFragment> | null;
        rows?: Array<{
          __typename?: 'TableRowResult';
          rowId: number;
          rowType: T.RowType;
          cells?: Array<{
            __typename?: 'TableCellResult';
            columnDataKey: string;
            cellContent?:
              | {
                  __typename?: 'KpiStatus';
                  text?: string | null;
                  icon: T.Icon;
                  sentiment: T.Sentiment;
                }
              | { __typename?: 'TextResult'; isHighlighted?: boolean | null; value?: string | null }
              | null;
          }> | null;
        }> | null;
      } | null;
    }> | null;
  }> | null;
};

export type TableCellContentFields_TimelineDataPoint_Fragment = {
  __typename?: 'TimelineDataPoint';
};

export type TableCellContentFields_TimelinePhases_Fragment = {
  __typename?: 'TimelinePhases';
  axis: { __typename?: 'TimelineAxis' } & TimelineAxisFieldsFragment;
  intervals?: Array<{ __typename?: 'TimelineInterval' } & TimelineIntervalFieldsFragment> | null;
};

export type TableCellContentFields_ValueWithVarianceResult_Fragment = {
  __typename: 'ValueWithVarianceResult';
} & ValueWithVarianceFieldsFragment;

export type TableCellContentFields_VarianceResult_Fragment = {
  __typename?: 'VarianceResult';
} & VarianceFieldsFragment;

export type TableCellContentFieldsFragment =
  | TableCellContentFields_BadgeResult_Fragment
  | TableCellContentFields_ColoredVarianceResult_Fragment
  | TableCellContentFields_ColoredVarianceWithDeltaResult_Fragment
  | TableCellContentFields_ComparatorTrendResult_Fragment
  | TableCellContentFields_CriteriaBadgeResult_Fragment
  | TableCellContentFields_DrillDownResult_Fragment
  | TableCellContentFields_GraphicElementResult_Fragment
  | TableCellContentFields_IconLinkResult_Fragment
  | TableCellContentFields_IconsLinkResult_Fragment
  | TableCellContentFields_KpiStatus_Fragment
  | TableCellContentFields_KpiStatusOverview_Fragment
  | TableCellContentFields_LocationMarkerResult_Fragment
  | TableCellContentFields_MicroChartResult_Fragment
  | TableCellContentFields_ProgressBarResult_Fragment
  | TableCellContentFields_RegionCountryResult_Fragment
  | TableCellContentFields_RiskIndicatorResult_Fragment
  | TableCellContentFields_StatusTextResult_Fragment
  | TableCellContentFields_StudyDesignLinkResult_Fragment
  | TableCellContentFields_TextExtendedResult_Fragment
  | TableCellContentFields_TextLinkResult_Fragment
  | TableCellContentFields_TextResult_Fragment
  | TableCellContentFields_Timeline_Fragment
  | TableCellContentFields_TimelineDataPoint_Fragment
  | TableCellContentFields_TimelinePhases_Fragment
  | TableCellContentFields_ValueWithVarianceResult_Fragment
  | TableCellContentFields_VarianceResult_Fragment;

export type TableCellFieldsFragment = {
  __typename?: 'TableCellResult';
  columnDataKey: string;
  cellContent?:
    | ({ __typename?: 'BadgeResult' } & TableCellContentFields_BadgeResult_Fragment)
    | ({
        __typename?: 'ColoredVarianceResult';
      } & TableCellContentFields_ColoredVarianceResult_Fragment)
    | ({
        __typename?: 'ColoredVarianceWithDeltaResult';
      } & TableCellContentFields_ColoredVarianceWithDeltaResult_Fragment)
    | ({
        __typename?: 'ComparatorTrendResult';
      } & TableCellContentFields_ComparatorTrendResult_Fragment)
    | ({ __typename?: 'CriteriaBadgeResult' } & TableCellContentFields_CriteriaBadgeResult_Fragment)
    | ({ __typename?: 'DrillDownResult' } & TableCellContentFields_DrillDownResult_Fragment)
    | ({
        __typename?: 'GraphicElementResult';
      } & TableCellContentFields_GraphicElementResult_Fragment)
    | ({ __typename?: 'IconLinkResult' } & TableCellContentFields_IconLinkResult_Fragment)
    | ({ __typename?: 'IconsLinkResult' } & TableCellContentFields_IconsLinkResult_Fragment)
    | ({ __typename?: 'KpiStatus' } & TableCellContentFields_KpiStatus_Fragment)
    | ({ __typename?: 'KpiStatusOverview' } & TableCellContentFields_KpiStatusOverview_Fragment)
    | ({
        __typename?: 'LocationMarkerResult';
      } & TableCellContentFields_LocationMarkerResult_Fragment)
    | ({ __typename?: 'MicroChartResult' } & TableCellContentFields_MicroChartResult_Fragment)
    | ({ __typename?: 'ProgressBarResult' } & TableCellContentFields_ProgressBarResult_Fragment)
    | ({ __typename?: 'RegionCountryResult' } & TableCellContentFields_RegionCountryResult_Fragment)
    | ({ __typename?: 'RiskIndicatorResult' } & TableCellContentFields_RiskIndicatorResult_Fragment)
    | ({ __typename?: 'StatusTextResult' } & TableCellContentFields_StatusTextResult_Fragment)
    | ({
        __typename?: 'StudyDesignLinkResult';
      } & TableCellContentFields_StudyDesignLinkResult_Fragment)
    | ({ __typename?: 'TextExtendedResult' } & TableCellContentFields_TextExtendedResult_Fragment)
    | ({ __typename?: 'TextLinkResult' } & TableCellContentFields_TextLinkResult_Fragment)
    | ({ __typename?: 'TextResult' } & TableCellContentFields_TextResult_Fragment)
    | ({ __typename?: 'Timeline' } & TableCellContentFields_Timeline_Fragment)
    | ({ __typename?: 'TimelineDataPoint' } & TableCellContentFields_TimelineDataPoint_Fragment)
    | ({ __typename?: 'TimelinePhases' } & TableCellContentFields_TimelinePhases_Fragment)
    | ({
        __typename?: 'ValueWithVarianceResult';
      } & TableCellContentFields_ValueWithVarianceResult_Fragment)
    | ({ __typename?: 'VarianceResult' } & TableCellContentFields_VarianceResult_Fragment)
    | null;
};

export type TableColumnOptionsFieldsFragment = {
  __typename?: 'TableColumnOptionsResult';
  alignment?: T.Alignment | null;
  isAiDriven?: boolean | null;
  selectDefault?: boolean | null;
  selectDisplayDefault: boolean;
  selectDisplayGroupId: number;
  selectGroupId: number;
  toggleDefault?: boolean | null;
  toggleGroup: number;
  width?: number | null;
};

export type TableColumnFieldsFragment = {
  __typename?: 'TableColumnResult';
  columnId: number;
  dataKey: string;
  mainColumnDataKey?: string | null;
  title?: string | null;
  options?: ({ __typename?: 'TableColumnOptionsResult' } & TableColumnOptionsFieldsFragment) | null;
};

export type TableComponentFieldsFragment = {
  __typename?: 'TableComponent';
  drillIds?: Array<number> | null;
  title: string;
  periodType: T.PeriodType;
  filters?: Array<{ __typename?: 'FilterComponent' } & FilterComponentFieldsFragment> | null;
} & ComponentFields_TableComponent_Fragment;

export type TableSwitcherComponentFieldsFragment = {
  __typename: 'TableSwitcherComponent';
  tables?: Array<{ __typename: 'TableComponent' } & TableComponentFieldsFragment> | null;
} & ComponentFields_TableSwitcherComponent_Fragment;

export type TableRowFieldsFragment = {
  __typename?: 'TableRowResult';
  rowId: number;
  rowType: T.RowType;
  cells?: Array<{ __typename?: 'TableCellResult' } & TableCellFieldsFragment> | null;
};

export type TextFieldsFragment = {
  __typename?: 'TextResult';
  isHighlighted?: boolean | null;
  value?: string | null;
  subLabels?: Array<string> | null;
  color?: { __typename?: 'ColorString'; color?: string | null } | null;
};

export type TextLinkFieldsFragment = {
  __typename?: 'TextLinkResult';
  value?: string | null;
  label?: string | null;
  subLabels?: Array<string> | null;
  linkDirection?: T.LinkDirection | null;
  linkResult?: ({ __typename?: 'Link' } & LinkFieldsFragment) | null;
};

export type IconsLinkFieldsFragment = {
  __typename?: 'IconsLinkResult';
  subLabels?: Array<string> | null;
  icons?: Array<string> | null;
  value?: string | null;
};

export type TimelineAxisFieldsFragment = {
  __typename?: 'TimelineAxis';
  minX: number;
  maxX: number;
  categories?: Array<string> | null;
};

export type TimelineIntervalFieldsFragment = {
  __typename?: 'TimelineInterval';
  name?: string | null;
  x1: number;
  x2: number;
  color: { __typename?: 'ThemeColor'; color?: T.Color | null };
};

export type TreeFilterOptionFieldsFragment = {
  __typename?: 'TreeFilterOptionResult';
  id: string;
  label: string;
  value?: number | null;
  isDefault?: boolean | null;
  additionalLabels?: Array<string> | null;
  sentiment?: T.Sentiment | null;
};

export type TreeMdFilterOptionFieldsFragment = {
  __typename?: 'TreeFilterOptionResult';
  id: string;
  label: string;
  value?: number | null;
  isDefault?: boolean | null;
  additionalLabels?: Array<string> | null;
  sentiment?: T.Sentiment | null;
  optionGroupCode?: string | null;
  parentNodeNames?: Array<string> | null;
};

export type TreeSelectFilterFieldsFragment = {
  __typename?: 'TreeSelectFilter';
  filterCode?: string | null;
  additionalLabels: Array<string | null>;
  options?: Array<
    {
      __typename?: 'TreeFilterOptionResult';
      children?: Array<
        {
          __typename?: 'TreeFilterOptionResult';
          children?: Array<
            { __typename?: 'TreeFilterOptionResult' } & TreeFilterOptionFieldsFragment
          > | null;
        } & TreeFilterOptionFieldsFragment
      > | null;
    } & TreeFilterOptionFieldsFragment
  > | null;
};

export type TreeSelectMdFilterFieldsFragment = {
  __typename?: 'TreeSelectFilter';
  filterCode?: string | null;
  additionalLabels: Array<string | null>;
  options?: Array<
    {
      __typename?: 'TreeFilterOptionResult';
      children?: Array<
        {
          __typename?: 'TreeFilterOptionResult';
          children?: Array<
            {
              __typename?: 'TreeFilterOptionResult';
              children?: Array<
                {
                  __typename?: 'TreeFilterOptionResult';
                  children?: Array<
                    {
                      __typename?: 'TreeFilterOptionResult';
                      children?: Array<
                        {
                          __typename?: 'TreeFilterOptionResult';
                          children?: Array<
                            {
                              __typename?: 'TreeFilterOptionResult';
                              children?: Array<
                                {
                                  __typename?: 'TreeFilterOptionResult';
                                  children?: Array<
                                    {
                                      __typename?: 'TreeFilterOptionResult';
                                      children?: Array<
                                        {
                                          __typename?: 'TreeFilterOptionResult';
                                          children?: Array<
                                            {
                                              __typename?: 'TreeFilterOptionResult';
                                            } & TreeMdFilterOptionFieldsFragment
                                          > | null;
                                        } & TreeMdFilterOptionFieldsFragment
                                      > | null;
                                    } & TreeMdFilterOptionFieldsFragment
                                  > | null;
                                } & TreeMdFilterOptionFieldsFragment
                              > | null;
                            } & TreeMdFilterOptionFieldsFragment
                          > | null;
                        } & TreeMdFilterOptionFieldsFragment
                      > | null;
                    } & TreeMdFilterOptionFieldsFragment
                  > | null;
                } & TreeMdFilterOptionFieldsFragment
              > | null;
            } & TreeMdFilterOptionFieldsFragment
          > | null;
        } & TreeMdFilterOptionFieldsFragment
      > | null;
    } & TreeMdFilterOptionFieldsFragment
  > | null;
};

export type UserSettingFieldsFragment = {
  __typename?: 'UserSetting';
  key: string;
  label: string;
  defaultValue?: number | null;
  userValue?: number | null;
  options: Array<{ __typename?: 'SettingOption' } & SettingOptionFieldsFragment>;
};

export type VarianceFieldsFragment = {
  __typename?: 'VarianceResult';
  actual?: string | null;
  target?: string | null;
  sentiment?: T.Sentiment | null;
  label?: string | null;
};

export type VideoContentFieldsFragment = {
  __typename?: 'VideoContent';
  id: string;
  sourceUrl: string;
  thumbnailUrl: string;
  lqipUrl?: string | null;
  title: string;
};

export type ValueTextFieldsFragment = {
  __typename?: 'ValueTextResult';
  value?: string | null;
  rawValue?: number | null;
  type?: T.ValueTextType | null;
  sentiment?: T.Sentiment | null;
};

export type ValueWithVarianceFieldsFragment = {
  __typename?: 'ValueWithVarianceResult';
  value?: string | null;
  absoluteVariance?: string | null;
  percentageVariance?: string | null;
  absoluteSentiment?: T.Sentiment | null;
  percentageSentiment?: T.Sentiment | null;
};

export type SettingOptionFieldsFragment = {
  __typename?: 'SettingOption';
  label: string;
  value: number;
};

export const ChartAxisFieldsFragmentDoc = gql`
  fragment ChartAxisFields on ChartAxis {
    ... on ChartAxisCategoryViewResult {
      categories
      renderer
      axisType
    }
    ... on ChartAxisValueViewResult {
      renderer
      axisType
      hasRawDataPointValues
    }
    ... on ChartAxisPercentViewResult {
      renderer
      axisType
    }
  }
`;
export const FormattedValueFieldsFragmentDoc = gql`
  fragment FormattedValueFields on FormattedValue {
    raw
    formatted
  }
`;
export const VarianceFieldsFragmentDoc = gql`
  fragment VarianceFields on VarianceResult {
    actual
    target
    sentiment
    label
  }
`;
export const ChartSeriesDataPointValueFieldsFragmentDoc = gql`
  fragment ChartSeriesDataPointValueFields on ChartSeriesDataPointValue {
    value {
      ...FormattedValueFields
    }
    high {
      ...FormattedValueFields
    }
    low {
      ...FormattedValueFields
    }
    median {
      ...FormattedValueFields
    }
    variance {
      ...VarianceFields
    }
  }
  ${FormattedValueFieldsFragmentDoc}
  ${VarianceFieldsFragmentDoc}
`;
export const ChartSeriesDataPointFieldsFragmentDoc = gql`
  fragment ChartSeriesDataPointFields on ChartSeriesDataPoint {
    x
    y {
      ...ChartSeriesDataPointValueFields
    }
  }
  ${ChartSeriesDataPointValueFieldsFragmentDoc}
`;
export const ChartSeriesFieldsFragmentDoc = gql`
  fragment ChartSeriesFields on ChartSeries {
    id
    seriesType
    linkedTo
    name
    color
    lineStyle
    showInLegend
    showInTooltip
    showLastPointMarker
    tooltipRangeFormat
    tooltipTitleLow
    tooltipTitleHigh
    tooltipTitleMedian
    tooltipCustomType
    renderer
    data {
      ...ChartSeriesDataPointFields
    }
  }
  ${ChartSeriesDataPointFieldsFragmentDoc}
`;
export const TextFieldsFragmentDoc = gql`
  fragment TextFields on TextResult {
    isHighlighted
    value
    subLabels
    color {
      ... on ColorString {
        color
      }
    }
  }
`;
export const FilterValueFieldsFragmentDoc = gql`
  fragment FilterValueFields on FilterValue {
    ... on MultiSelectFilterValue {
      id
      selectedValues
    }
    ... on SelectFilterValue {
      id
      value
    }
    ... on SelectGroupFilterValue {
      id
      values {
        filterCode
        value
      }
    }
  }
`;
export const LinkFieldsFragmentDoc = gql`
  fragment LinkFields on Link {
    pageId
    absolutePath
    drillId
    drillIds
    filters {
      ...FilterValueFields
    }
    target
    plailistId
    previousPriorityId
  }
  ${FilterValueFieldsFragmentDoc}
`;
export const TextLinkFieldsFragmentDoc = gql`
  fragment TextLinkFields on TextLinkResult {
    value
    label
    subLabels
    linkResult {
      ...LinkFields
    }
    linkDirection
  }
  ${LinkFieldsFragmentDoc}
`;
export const DialItemFieldsFragmentDoc = gql`
  fragment DialItemFields on DialItem {
    label
    sentiment
    progress
    progressText {
      value
    }
    target
    targetText {
      value
    }
    actualText {
      value
    }
    varianceText {
      ...TextFields
    }
    varianceAIText {
      ...TextFields
    }
    link {
      ...TextLinkFields
    }
  }
  ${TextFieldsFragmentDoc}
  ${TextLinkFieldsFragmentDoc}
`;
export const FilterFieldsFragmentDoc = gql`
  fragment FilterFields on Filter {
    id
    label
    filterType
  }
`;
export const InterceptFieldsFragmentDoc = gql`
  fragment InterceptFields on InterceptResult {
    id
    name
    title
    description
    link {
      ...LinkFields
    }
    linkText
    cancelText
    media {
      ... on ImageResult {
        imageUrl
      }
      id
      title
      thumbnailUrl
    }
  }
  ${LinkFieldsFragmentDoc}
`;
export const KpiStatusFieldsFragmentDoc = gql`
  fragment KpiStatusFields on KpiStatus {
    actual
    target
    sentiment
    icon
    iconLink {
      icon
      linkResult {
        ...LinkFields
      }
    }
  }
  ${LinkFieldsFragmentDoc}
`;
export const KpiCardFieldsFragmentDoc = gql`
  fragment KpiCardFields on KpiCardResult {
    id
    code
    cardType
    title {
      ...TextFields
    }
    items {
      ...KpiStatusFields
    }
    linkResult {
      ...LinkFields
    }
  }
  ${TextFieldsFragmentDoc}
  ${KpiStatusFieldsFragmentDoc}
  ${LinkFieldsFragmentDoc}
`;
export const MediaFieldsFragmentDoc = gql`
  fragment MediaFields on Media {
    id
    title
    thumbnailUrl
  }
`;
export const MetricFieldsFragmentDoc = gql`
  fragment MetricFields on Metric {
    id
    name
    value
    sentiment
    variances {
      ...VarianceFields
    }
  }
  ${VarianceFieldsFragmentDoc}
`;
export const MetricListFieldsFragmentDoc = gql`
  fragment MetricListFields on MetricList {
    name
    metrics {
      ...MetricFields
    }
  }
  ${MetricFieldsFragmentDoc}
`;
export const MicroChartFieldsFragmentDoc = gql`
  fragment MicroChartFields on MicroChartResult {
    seriesData
    trendlineData
    sentiment
  }
`;
export const MicroChartCardFieldsFragmentDoc = gql`
  fragment MicroChartCardFields on MicroChartCardResult {
    id
    code
    cardType
    title {
      ...TextFields
    }
    actual {
      ...TextFields
    }
    variance {
      ...VarianceFields
    }
    microChart {
      ...MicroChartFields
    }
    linkResult {
      ...LinkFields
    }
  }
  ${TextFieldsFragmentDoc}
  ${VarianceFieldsFragmentDoc}
  ${MicroChartFieldsFragmentDoc}
  ${LinkFieldsFragmentDoc}
`;
export const PageFieldsFragmentDoc = gql`
  fragment PageFields on Page {
    id
    title
    pageType
    menuTitle
    menuIcon
    path
    absolutePath
    isMenuHidden
  }
`;
export const ModuleFieldsFragmentDoc = gql`
  fragment ModuleFields on Module {
    id
    name
    moduleCode
    path
    pages {
      ...PageFields
      children {
        ...PageFields
        children {
          ...PageFields
          children {
            ...PageFields
            children {
              ...PageFields
            }
          }
        }
      }
    }
  }
  ${PageFieldsFragmentDoc}
`;
export const ComponentFieldsFragmentDoc = gql`
  fragment ComponentFields on Component {
    id
    code
    componentType
    icon
  }
`;
export const TabFieldsFragmentDoc = gql`
  fragment TabFields on Tab {
    title
    path
    pageId
  }
`;
export const NavigationTabsComponentFieldsFragmentDoc = gql`
  fragment NavigationTabsComponentFields on NavigationTabsComponent {
    ...ComponentFields
    tabs {
      ...TabFields
    }
  }
  ${ComponentFieldsFragmentDoc}
  ${TabFieldsFragmentDoc}
`;
export const PlailistFilterOptionFieldsFragmentDoc = gql`
  fragment PlailistFilterOptionFields on PlailistFilterOption {
    id
    value
    label
    isDefault
  }
`;
export const PlailistFilterFieldsFragmentDoc = gql`
  fragment PlailistFilterFields on PlailistFilter {
    filterCode
    options {
      ...PlailistFilterOptionFields
      children {
        ...PlailistFilterOptionFields
        children {
          ...PlailistFilterOptionFields
          children {
            ...PlailistFilterOptionFields
            children {
              ...PlailistFilterOptionFields
            }
          }
        }
      }
    }
  }
  ${PlailistFilterOptionFieldsFragmentDoc}
`;
export const FilterComponentFieldsFragmentDoc = gql`
  fragment FilterComponentFields on FilterComponent {
    ...ComponentFields
    defaultValue
    alignment
    isHidden
    displayMode
    filterSynchronizationGroup
  }
  ${ComponentFieldsFragmentDoc}
`;
export const TableComponentFieldsFragmentDoc = gql`
  fragment TableComponentFields on TableComponent {
    ...ComponentFields
    drillIds
    title
    filters {
      ...FilterComponentFields
    }
    periodType
  }
  ${ComponentFieldsFragmentDoc}
  ${FilterComponentFieldsFragmentDoc}
`;
export const ReferenceFieldsFragmentDoc = gql`
  fragment ReferenceFields on ExternalLink {
    displayText
    url
  }
`;
export const StoryFieldsFragmentDoc = gql`
  fragment StoryFields on Story {
    id
    insightsType
    storyType
    headline
    recommenderHeadline
    storyDataModule
    caption
    decision {
      isTaken
      screenIds
      title
      subTitle
    }
    isAddedToBrain
    brainsCount
    formattedValue
    module
    content {
      ... on MarkdownComponent {
        ...ComponentFields
        content
      }
      ... on TableComponent {
        ...TableComponentFields
      }
      ... on ChartComponent {
        ...ComponentFields
        drillIds
        filters {
          ...FilterComponentFields
        }
      }
      ... on HeaderRowComponent {
        ...ComponentFields
        drillIds
        filters {
          ...FilterComponentFields
        }
        variant
      }
      ... on VideoComponent {
        ...ComponentFields
        videoContent {
          ... on VideoContent {
            id
            thumbnailUrl
            lqipUrl
            sourceUrl
          }
        }
      }
    }
    sentiment
    publishDate
    isRead
    tag
    link {
      ...LinkFields
    }
    legacyLink {
      filters {
        filterCode
        filterLabel
        filterStringValue
        filterValue
      }
      focusAreaCode
      moduleCode
      pageCode
      path
      queryParams
      viewCode
      visibleColumns
    }
    stamp
    hasFeedback
    isLiked
    timeStamp
    reference {
      ...ReferenceFields
    }
  }
  ${ComponentFieldsFragmentDoc}
  ${TableComponentFieldsFragmentDoc}
  ${FilterComponentFieldsFragmentDoc}
  ${LinkFieldsFragmentDoc}
  ${ReferenceFieldsFragmentDoc}
`;
export const PlailistFieldsFragmentDoc = gql`
  fragment PlailistFields on Plailist {
    plailistId
    title
    summary
    filters {
      ... on PlailistFilter {
        ...PlailistFilterFields
      }
      __typename
    }
    colorStart {
      ... on ColorString {
        color
      }
    }
    colorEnd {
      ... on ColorString {
        color
      }
    }
    stories {
      ...StoryFields
    }
  }
  ${PlailistFilterFieldsFragmentDoc}
  ${StoryFieldsFragmentDoc}
`;
export const PlailistCategoryFieldsFragmentDoc = gql`
  fragment PlailistCategoryFields on PlailistCategory {
    categoryId
    name
    plailists {
      ...PlailistFields
    }
  }
  ${PlailistFieldsFragmentDoc}
`;
export const MetaDataFieldsFragmentDoc = gql`
  fragment MetaDataFields on MetaData {
    key
    label
    value
  }
`;
export const PlailistsResultFieldsFragmentDoc = gql`
  fragment PlailistsResultFields on PlailistsResult {
    categories {
      ...PlailistCategoryFields
    }
    metadata {
      ...MetaDataFields
    }
  }
  ${PlailistCategoryFieldsFragmentDoc}
  ${MetaDataFieldsFragmentDoc}
`;
export const PriorityFieldsFragmentDoc = gql`
  fragment PriorityFields on PriorityResult {
    priorityId
    priorityName
    isFollowed
    sortOrder
    subLabel
  }
`;
export const PriorityCategoryFieldsFragmentDoc = gql`
  fragment PriorityCategoryFields on PriorityCategoryResult {
    categoryName
    priorities {
      ...PriorityFields
    }
  }
  ${PriorityFieldsFragmentDoc}
`;
export const SettingOptionFieldsFragmentDoc = gql`
  fragment SettingOptionFields on SettingOption {
    label
    value
  }
`;
export const UserSettingFieldsFragmentDoc = gql`
  fragment UserSettingFields on UserSetting {
    key
    label
    options {
      ...SettingOptionFields
    }
    defaultValue
    userValue
  }
  ${SettingOptionFieldsFragmentDoc}
`;
export const DataViewFieldsFragmentDoc = gql`
  fragment DataViewFields on IDataView {
    id
    dataViewType
    metadata {
      ...MetaDataFields
    }
    availableUserSettings {
      ...UserSettingFields
    }
  }
  ${MetaDataFieldsFragmentDoc}
  ${UserSettingFieldsFragmentDoc}
`;
export const RecommendationItemFieldsFragmentDoc = gql`
  fragment RecommendationItemFields on RecommendationItem {
    title
    description
    cost
    icon
    sortOrder
    value
    details {
      ... on RecommendationItemDetail {
        cost
        id
        whatIfDeepLink {
          ... on TextLinkResult {
            ...TextLinkFields
          }
        }
        title
        value
        isPinned
        itemsContent {
          ... on RecommendationItemContent {
            icon
            title
          }
        }
      }
      __typename
    }
    __typename
  }
  ${TextLinkFieldsFragmentDoc}
`;
export const RecommendationsListFieldsFragmentDoc = gql`
  fragment RecommendationsListFields on RecommendationsList {
    title
    label
    items {
      ...RecommendationItemFields
    }
    link {
      ...LinkFields
    }
    sentiment
  }
  ${RecommendationItemFieldsFragmentDoc}
  ${LinkFieldsFragmentDoc}
`;
export const RecommendationsActionsDataViewFieldsFragmentDoc = gql`
  fragment RecommendationsActionsDataViewFields on RecommendationsActionsDataView {
    ...DataViewFields
    recommendationsList {
      ...RecommendationsListFields
    }
    pageHeader
    pageSubheader
  }
  ${DataViewFieldsFragmentDoc}
  ${RecommendationsListFieldsFragmentDoc}
`;
export const RecommendationsBreakdownFieldsFragmentDoc = gql`
  fragment RecommendationsBreakdownFields on RecommendationsBreakdown {
    dataViewId
    viewCode
    title
  }
`;
export const RecommendationsBreakdownDataViewFieldsFragmentDoc = gql`
  fragment RecommendationsBreakdownDataViewFields on RecommendationsBreakdownDataView {
    ...DataViewFields
    breakdowns {
      ...RecommendationsBreakdownFields
    }
  }
  ${DataViewFieldsFragmentDoc}
  ${RecommendationsBreakdownFieldsFragmentDoc}
`;
export const RecommendationsActionsComponentFieldsFragmentDoc = gql`
  fragment RecommendationsActionsComponentFields on RecommendationsActionsComponent {
    filters {
      ...FilterComponentFields
    }
    id
    componentType
    viewType
    code
    icon
  }
  ${FilterComponentFieldsFragmentDoc}
`;
export const RecommendationsBreakdownComponentFieldsFragmentDoc = gql`
  fragment RecommendationsBreakdownComponentFields on RecommendationsBreakdownComponent {
    filters {
      ...FilterComponentFields
    }
    id
    componentType
    viewType
    code
    icon
  }
  ${FilterComponentFieldsFragmentDoc}
`;
export const RealFieldsFragmentDoc = gql`
  fragment RealFields on Real {
    id
    title
    insightsType
    hasSeenAllScreens
    isRead
    header {
      title
      subtitle
      icon
    }
    isShareable
    screens {
      screenId
      screenType
      header {
        title
        subtitle
        icon
      }
      audio {
        trackName
      }
      seeMoreLink {
        subLabels
        value
        label
        linkDirection
        linkResult {
          pageId
          absolutePath
          ...LinkFields
          __typename
        }
        linkDirection
      }
      content {
        __typename
        items {
          text {
            content
            sentiment
            size
          }
          icon
        }
      }
      __typename
    }
    __typename
  }
  ${LinkFieldsFragmentDoc}
`;
export const RecommenderDataViewFieldsFragmentDoc = gql`
  fragment RecommenderDataViewFields on RecommenderDataView {
    ...DataViewFields
    insights {
      ... on Story {
        ...StoryFields
      }
      ... on Real {
        ...RealFields
      }
    }
    seeAllLink {
      ...LinkFields
    }
  }
  ${DataViewFieldsFragmentDoc}
  ${StoryFieldsFragmentDoc}
  ${RealFieldsFragmentDoc}
  ${LinkFieldsFragmentDoc}
`;
export const FilterOptionFieldsFragmentDoc = gql`
  fragment FilterOptionFields on FilterOptionResult {
    id
    label
    value
    isDefault
    isTarget
    additionalLabels
    sentiment
  }
`;
export const SelectFilterFieldsFragmentDoc = gql`
  fragment SelectFilterFields on SelectFilter {
    filterCode
    additionalLabels
    options {
      ...FilterOptionFields
    }
  }
  ${FilterOptionFieldsFragmentDoc}
`;
export const SelectGroupFilterFieldsFragmentDoc = gql`
  fragment SelectGroupFilterFields on SelectGroupFilter {
    filterCode
    additionalLabels
    filters {
      ... on SelectFilter {
        id
        additionalLabels
        filterCode
        label
        options {
          ...FilterOptionFields
        }
      }
    }
  }
  ${FilterOptionFieldsFragmentDoc}
`;
export const SimpleCardFieldsFragmentDoc = gql`
  fragment SimpleCardFields on SimpleCardResult {
    id
    code
    cardType
    title {
      ...TextFields
    }
    textResult {
      ...TextFields
    }
    linkResult {
      ...LinkFields
    }
    variance {
      ...VarianceFields
    }
  }
  ${TextFieldsFragmentDoc}
  ${LinkFieldsFragmentDoc}
  ${VarianceFieldsFragmentDoc}
`;
export const SummaryInfoItemFieldsFragmentDoc = gql`
  fragment SummaryInfoItemFields on SummaryInfoItem {
    header {
      ... on TextResult {
        value
      }
      ... on TextLinkResult {
        ...TextLinkFields
      }
    }
    content {
      ... on TextResult {
        value
      }
    }
  }
  ${TextLinkFieldsFragmentDoc}
`;
export const TableSwitcherComponentFieldsFragmentDoc = gql`
  fragment TableSwitcherComponentFields on TableSwitcherComponent {
    ...ComponentFields
    tables {
      ...TableComponentFields
      __typename
    }
    __typename
  }
  ${ComponentFieldsFragmentDoc}
  ${TableComponentFieldsFragmentDoc}
`;
export const ValueWithVarianceFieldsFragmentDoc = gql`
  fragment ValueWithVarianceFields on ValueWithVarianceResult {
    value
    absoluteVariance
    percentageVariance
    absoluteSentiment
    percentageSentiment
  }
`;
export const ProgressBarFieldsFragmentDoc = gql`
  fragment ProgressBarFields on ProgressBarResult {
    progressValue
    baseValue
    min
    max
    sentiment
    inverted
    variant
  }
`;
export const TableColumnOptionsFieldsFragmentDoc = gql`
  fragment TableColumnOptionsFields on TableColumnOptionsResult {
    alignment
    isAiDriven
    selectDefault
    selectDisplayDefault
    selectDisplayGroupId
    selectGroupId
    toggleDefault
    toggleGroup
    width
  }
`;
export const TableColumnFieldsFragmentDoc = gql`
  fragment TableColumnFields on TableColumnResult {
    columnId
    dataKey
    mainColumnDataKey
    title
    options {
      ...TableColumnOptionsFields
    }
  }
  ${TableColumnOptionsFieldsFragmentDoc}
`;
export const KpiStatusOverviewFieldsFragmentDoc = gql`
  fragment KpiStatusOverviewFields on KpiStatusOverview {
    ...DataViewFields
    items {
      ...KpiStatusFields
    }
    tooltip {
      title
      columns {
        ...TableColumnFields
      }
      rows {
        rowId
        rowType
        cells {
          columnDataKey
          cellContent {
            ... on KpiStatus {
              text
              icon
              sentiment
            }
            ... on TextResult {
              isHighlighted
              value
            }
          }
        }
      }
    }
  }
  ${DataViewFieldsFragmentDoc}
  ${KpiStatusFieldsFragmentDoc}
  ${TableColumnFieldsFragmentDoc}
`;
export const IconsLinkFieldsFragmentDoc = gql`
  fragment IconsLinkFields on IconsLinkResult {
    subLabels
    icons
    value
  }
`;
export const TimelineAxisFieldsFragmentDoc = gql`
  fragment TimelineAxisFields on TimelineAxis {
    minX
    maxX
    categories
  }
`;
export const TimelineIntervalFieldsFragmentDoc = gql`
  fragment TimelineIntervalFields on TimelineInterval {
    name
    x1
    x2
    color {
      color
    }
  }
`;
export const TableCellContentFieldsFragmentDoc = gql`
  fragment TableCellContentFields on TableCellContent {
    ... on TextResult {
      value
      subLabels
      isHighlighted
    }
    ... on ValueWithVarianceResult {
      ...ValueWithVarianceFields
      __typename
    }
    ... on VarianceResult {
      ...VarianceFields
    }
    ... on DrillDownResult {
      value
      subLabels
      drillUpId
      drillDownId
      drillUpIds
      drillDownIds
      dimensionIds
    }
    ... on MicroChartResult {
      ...MicroChartFields
      linkResult {
        ...LinkFields
      }
    }
    ... on ProgressBarResult {
      ...ProgressBarFields
    }
    ... on TextLinkResult {
      ...TextLinkFields
    }
    ... on KpiStatusOverview {
      ...KpiStatusOverviewFields
    }
    ... on IconsLinkResult {
      ...IconsLinkFields
      linkResult {
        ...LinkFields
      }
    }
    ... on LocationMarkerResult {
      text
      position {
        coordinates {
          latitude
          longitude
        }
      }
    }
    ... on Timeline {
      axis {
        ...TimelineAxisFields
      }
      intervals {
        ...TimelineIntervalFields
      }
      series {
        id
        name
        sortOrder
        marker {
          symbol
          isFilled
          color {
            color
          }
        }
        data {
          x
          formattedX
          label
          marker {
            symbol
            isFilled
            color {
              color
            }
          }
          tooltip {
            title
            columns {
              ...TableColumnFields
            }
            rows {
              rowId
              rowType
              cells {
                columnDataKey
                cellContent {
                  ... on KpiStatus {
                    text
                    icon
                    sentiment
                  }
                  ... on TextResult {
                    isHighlighted
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
    ... on TimelinePhases {
      axis {
        ...TimelineAxisFields
      }
      intervals {
        ...TimelineIntervalFields
      }
    }
    ... on ComparatorTrendResult {
      leftValue {
        value
        sentiment
      }
      rightValue
      trendArrow {
        direction
        sentiment
      }
    }
    ... on RiskIndicatorResult {
      actual
      previousSentiment
      sentiment
      target
    }
    ... on BadgeResult {
      content
      sentiment
    }
    ... on IconLinkResult {
      icon
      linkResult {
        ...LinkFields
      }
      showIcon
    }
    ... on RegionCountryResult {
      regionCode
      regionName
      countryCode
      countryName
    }
    ... on ColoredVarianceResult {
      actual
      label
      sentiment
      subLabels
      target
      __typename
    }
    ... on ColoredVarianceWithDeltaResult {
      actual
      sentiment
      deltaValues {
        actual
        sentiment
      }
    }
  }
  ${ValueWithVarianceFieldsFragmentDoc}
  ${VarianceFieldsFragmentDoc}
  ${MicroChartFieldsFragmentDoc}
  ${LinkFieldsFragmentDoc}
  ${ProgressBarFieldsFragmentDoc}
  ${TextLinkFieldsFragmentDoc}
  ${KpiStatusOverviewFieldsFragmentDoc}
  ${IconsLinkFieldsFragmentDoc}
  ${TimelineAxisFieldsFragmentDoc}
  ${TimelineIntervalFieldsFragmentDoc}
  ${TableColumnFieldsFragmentDoc}
`;
export const TableCellFieldsFragmentDoc = gql`
  fragment TableCellFields on TableCellResult {
    columnDataKey
    cellContent {
      ...TableCellContentFields
    }
  }
  ${TableCellContentFieldsFragmentDoc}
`;
export const TableRowFieldsFragmentDoc = gql`
  fragment TableRowFields on TableRowResult {
    rowId
    rowType
    cells {
      ...TableCellFields
    }
  }
  ${TableCellFieldsFragmentDoc}
`;
export const TreeFilterOptionFieldsFragmentDoc = gql`
  fragment TreeFilterOptionFields on TreeFilterOptionResult {
    id
    label
    value
    isDefault
    additionalLabels
    sentiment
  }
`;
export const TreeSelectFilterFieldsFragmentDoc = gql`
  fragment TreeSelectFilterFields on TreeSelectFilter {
    filterCode
    additionalLabels
    options {
      ...TreeFilterOptionFields
      children {
        ...TreeFilterOptionFields
        children {
          ...TreeFilterOptionFields
        }
      }
    }
  }
  ${TreeFilterOptionFieldsFragmentDoc}
`;
export const TreeMdFilterOptionFieldsFragmentDoc = gql`
  fragment TreeMDFilterOptionFields on TreeFilterOptionResult {
    id
    label
    value
    isDefault
    additionalLabels
    sentiment
    optionGroupCode
    parentNodeNames
  }
`;
export const TreeSelectMdFilterFieldsFragmentDoc = gql`
  fragment TreeSelectMDFilterFields on TreeSelectFilter {
    filterCode
    additionalLabels
    options {
      ...TreeMDFilterOptionFields
      children {
        ...TreeMDFilterOptionFields
        children {
          ...TreeMDFilterOptionFields
          children {
            ...TreeMDFilterOptionFields
            children {
              ...TreeMDFilterOptionFields
              children {
                ...TreeMDFilterOptionFields
                children {
                  ...TreeMDFilterOptionFields
                  children {
                    ...TreeMDFilterOptionFields
                    children {
                      ...TreeMDFilterOptionFields
                      children {
                        ...TreeMDFilterOptionFields
                        children {
                          ...TreeMDFilterOptionFields
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${TreeMdFilterOptionFieldsFragmentDoc}
`;
export const VideoContentFieldsFragmentDoc = gql`
  fragment VideoContentFields on VideoContent {
    id
    sourceUrl
    thumbnailUrl
    lqipUrl
    title
  }
`;
export const ValueTextFieldsFragmentDoc = gql`
  fragment ValueTextFields on ValueTextResult {
    value
    rawValue
    type
    sentiment
  }
`;
