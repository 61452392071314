import { styled } from '@mui/material/styles';
import React, { forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import { ItemContent, TableComponents, TableVirtuoso, TableVirtuosoHandle } from 'react-virtuoso';

import { ExpandButton } from '../../buttons';
import { Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '../../Table';
import { TableRendererProps } from './TableRenderer';
import { computeExpandedData } from './utils';

const Indent = styled('span')<{ level?: number }>(({ theme, level = 0 }) => ({
  marginRight: level * parseInt(theme.tokens.spacing['medium'], 10),
}));

export interface ExpandableRowType<RowType> {
  row: RowType;
  level: number;
}

const ExpandableTableRenderer = <RowType, ContextType>({
  data,
  columns,
  rowKey,
  context,
  onCellRender,
  onHeaderCellRender,
  isRowExpandable,
  getChildrenRows,
  EmptyPlaceholder = () => <TableBody />,
}: TableRendererProps<RowType, ContextType>) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<Set<string | number>>(new Set());
  const virtuosoRef = useRef<TableVirtuosoHandle | null>(null);

  const components: TableComponents<ExpandableRowType<RowType>, ContextType> = useMemo(
    () => ({
      Table,
      TableHead: forwardRef((props, ref) => <TableHead sticky {...props} ref={ref} />),
      TableBody,
      TableFoot: forwardRef((props, ref) => <TableFooter sticky {...props} ref={ref} />),
      TableRow,
      EmptyPlaceholder,
    }),
    [EmptyPlaceholder],
  );

  const expandedData = useMemo(
    () => computeExpandedData(data, rowKey, getChildrenRows, expandedRowKeys),
    [data, expandedRowKeys, rowKey, getChildrenRows],
  );

  const handleRowToggle = useCallback((key: string | number) => {
    setExpandedRowKeys((prev) => {
      const newSet = new Set(prev);

      if (newSet.has(key)) {
        newSet.delete(key);
      } else {
        newSet.add(key);
      }

      return newSet;
    });
  }, []);

  const itemContent: ItemContent<ExpandableRowType<RowType>, ContextType> = useCallback(
    (index, { row, level }, context) => {
      const key = rowKey(row);
      const isExpandable = isRowExpandable?.(row);
      const isExpanded = expandedRowKeys.has(key);

      return (
        <React.Fragment key={key}>
          {columns.map((column, columnIndex) => (
            <TableCell key={column.dataKey} align={column.align}>
              {columnIndex === 0 && <Indent level={level} />}
              {columnIndex === 0 && isExpandable ? (
                <ExpandButton expanded={isExpanded} onClick={() => handleRowToggle(key)}>
                  {onCellRender?.(row, column, columnIndex, context)}
                </ExpandButton>
              ) : (
                onCellRender?.(row, column, columnIndex, context)
              )}
            </TableCell>
          ))}
        </React.Fragment>
      );
    },
    [columns, expandedRowKeys, handleRowToggle, isRowExpandable, onCellRender, rowKey],
  );

  const fixedHeaderContent = useCallback(
    () => (
      <TableRow>
        {columns.map((column, columnIndex) => (
          <TableCell key={column.dataKey} variant="head" width={column.width} align={column.align}>
            {onHeaderCellRender?.(column, columnIndex) ?? column.label}
          </TableCell>
        ))}
      </TableRow>
    ),
    [columns, onHeaderCellRender],
  );

  return (
    <TableVirtuoso<ExpandableRowType<RowType>, ContextType>
      ref={virtuosoRef}
      data={expandedData}
      context={context}
      fixedHeaderContent={fixedHeaderContent}
      itemContent={itemContent}
      components={components}
    />
  );
};

export default ExpandableTableRenderer;
