import { ColorTokenKey, IconSizingTokenKey } from '@aily/ui-theme';
import { styled } from '@mui/material';
import React from 'react';

export interface SvgIconProps extends React.SVGProps<SVGSVGElement> {
  size?: IconSizingTokenKey;
  color?: ColorTokenKey;
  children?: React.ReactNode;
}

const SvgIconRoot = styled('svg', {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'color',
})<SvgIconProps>(({ theme, size = 'icon.small', color }) => {
  const sizeValue = theme.tokens.sizing[size];
  const colorValue = color ? theme.tokens.color[color] : undefined;

  return {
    display: 'inline-block',
    width: '1em',
    height: '1em',
    flexShrink: 0,
    fontSize: sizeValue,
    color: colorValue,
    fill: 'currentColor',
    userSelect: 'none',
  };
});

const SvgIcon: React.FC<SvgIconProps> = ({ size = 'icon.small', color, ...rest }) => (
  <SvgIconRoot size={size} color={color} {...rest} />
);

export default SvgIcon;
