import { buttonClasses } from '@mui/base';
import { styled } from '@mui/material/styles';
import React from 'react';

import { IconWrapper, PlusIcon } from '../../icons';
import { ButtonBase, ButtonBaseProps } from '../ButtonBase';

export interface AddButtonProps extends ButtonBaseProps {}

const AddButtonRoot = styled(ButtonBase)(({ theme }) => ({
  gap: theme.tokens.spacing['xxsmall'],
  height: 40,

  backgroundColor: 'transparent',
  color: theme.tokens.color['text.regular'],

  ...theme.tokens.typography['heading.button'],
  lineHeight: 1,

  [`&.${buttonClasses.disabled}`]: {
    color: theme.tokens.color['text.disabled'],
  },
}));

const AddButton: React.FC<AddButtonProps> = ({ children, ...rest }) => (
  <AddButtonRoot {...rest}>
    <IconWrapper>
      <PlusIcon size="icon.small" />
    </IconWrapper>
    {children}
  </AddButtonRoot>
);

export default AddButton;
