import { ColorTokenKey, TypographyTokenKey } from '@aily/ui-theme';
import { styled, SxProps, Theme } from '@mui/material/styles';
import React from 'react';

import { getLineClampStyles, getTextColorStyles } from '../../utils';

export interface TypographyProps {
  children?: React.ReactNode;
  variant?: TypographyTokenKey;
  color?: ColorTokenKey;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  lineClamp?: number;
  sx?: SxProps<Theme>;
}

const customProps = ['variant', 'color', 'align', 'lineClamp', 'sx'];

const TypographyRoot = styled('div', {
  shouldForwardProp: (prop) => !customProps.includes(prop as string),
})<TypographyProps>(({ theme, variant = 'body.regular', color, align, lineClamp }) => {
  const typographyStyles = theme.tokens.typography[variant];
  const colorValue = color ? theme.tokens.color[color] : undefined;

  return {
    ...typographyStyles,
    textAlign: align,
    ...getTextColorStyles(colorValue),
    ...getLineClampStyles(lineClamp),
  };
});

const Typography: React.FC<TypographyProps> = (props) => <TypographyRoot {...props} />;

export default Typography;
