import { TypographyTokenValue } from '../../../types';

// Contains a fallback because we have different font names
const fontFamily = 'SF Pro, SF-Pro-Display';

// Define token object with strict type checking
const typography = {
  'heading.H1': {
    fontWeight: '300',
    fontFamily,
    fontSize: '72px',
    lineHeight: '120%',
  },
  'heading.H2': {
    fontFamily,
    fontWeight: '300',
    fontSize: '64px',
    lineHeight: '120%',
  },
  'heading.H3': {
    fontFamily,
    fontWeight: '400',
    lineHeight: '120%',
    fontSize: '48px',
  },
  'heading.H3-bold': {
    fontFamily,
    fontWeight: '700',
    fontSize: '48px',
    lineHeight: '100%',
  },
  'heading.H4': {
    fontFamily,
    fontWeight: '400',
    fontSize: '36px',
    lineHeight: '120%',
  },
  'heading.H5': {
    fontFamily,
    fontWeight: '300',
    fontSize: '32px',
    lineHeight: '120%',
  },
  'heading.H6': {
    fontFamily,
    fontWeight: '400',
    fontSize: '24px',
    lineHeight: '120%',
  },
  'heading.H7': {
    fontFamily,
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '120%',
  },
  'heading.H8': {
    fontFamily,
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '120%',
  },
  'heading.H9': {
    fontFamily,
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '120%',
  },
  'heading.impact': {
    fontFamily,
    fontWeight: '300',
    fontSize: '28px',
    lineHeight: '120%',
  },
  'heading.button': {
    fontFamily,
    fontWeight: '800',
    fontSize: '12px',
    letterSpacing: '0.08em',
    textTransform: 'uppercase',
    lineHeight: '180%',
  },
  'body.tiny': {
    fontFamily,
    fontWeight: '500',
    fontSize: '10px',
    lineHeight: '180%',
  },
  'body.tiny-condensed': {
    fontFamily,
    fontWeight: '500',
    fontSize: '10px',
    lineHeight: '120%',
  },
  'body.tiny-bold': {
    fontFamily,
    fontWeight: '800',
    fontSize: '10px',
    lineHeight: '180%',
  },
  'body.tiny-bold-condensed': {
    fontFamily,
    fontWeight: '800',
    fontSize: '10px',
    lineHeight: '120%',
  },
  'body.xsmall': {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '180%',
    fontFamily,
  },
  'body.xsmall-bold': {
    fontFamily,
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '180%',
  },
  'body.xsmall-condensed': {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '120%',
    fontFamily,
  },
  'body.xsmall-bold-condensed': {
    fontFamily,
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '120%',
  },
  'body.small': {
    fontFamily,
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '180%',
  },
  'body.small-bold': {
    fontFamily,
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '180%',
  },
  'body.small-condensed': {
    fontFamily,
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '120%',
  },
  'body.small-bold-condensed': {
    fontFamily,
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '120%',
  },
  'body.regular': {
    fontFamily,
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '180%',
  },
  'body.bold': {
    fontFamily,
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '180%',
  },
  'body.regular-condensed': {
    fontFamily,
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '120%',
  },
  'body.bold-condensed': {
    fontFamily,
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '120%',
  },
  'body.large': {
    fontFamily,
    fontWeight: '300',
    fontSize: '18px',
    lineHeight: '150%',
  },
  'body.large-condensed': {
    fontFamily,
    fontWeight: '300',
    fontSize: '18px',
    lineHeight: '120%',
  },
} as const satisfies Record<string, TypographyTokenValue>;

export default typography;
