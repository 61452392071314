import React, { useId } from 'react';

export const AgentGlowBackground = () => {
  const id = useId();

  return (
    <svg
      width={136}
      height={124}
      fill="none"
      style={{
        bottom: 0,
        right: 0,
        position: 'absolute',
        borderRadius: 12,
      }}
    >
      <g filter={`url(#${id}-1)`}>
        <circle cx={108.705} cy={108.705} r={45.705} fill="#000" />
      </g>
      <g filter={`url(#${id}-2)`}>
        <ellipse cx={109.086} cy={111.943} fill="#38E787" rx={41.515} ry={39.421} />
      </g>
      <g filter={`url(#${id}-3)`}>
        <ellipse cx={121.363} cy={101.153} fill="#fff" rx={17.613} ry={16.385} />
      </g>
      <defs>
        <filter
          id={`${id}-1`}
          width={216.41}
          height={216.41}
          x={0.5}
          y={0.5}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur" stdDeviation={31.25} />
        </filter>
        <filter
          id={`${id}-2`}
          width={183.031}
          height={178.841}
          x={17.57}
          y={22.523}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur" stdDeviation={25} />
        </filter>
        <filter
          id={`${id}-3`}
          width={85.226}
          height={82.77}
          x={78.75}
          y={59.768}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur" stdDeviation={12.5} />
        </filter>
      </defs>
    </svg>
  );
};
