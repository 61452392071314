import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const ChevronRightSmall: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="18" height="19" viewBox="0 0 18 19" {...props}>
    <path
      d="M7.31921 6.08183L10.6808 9.50002L7.31921 12.9182"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ChevronRightSmall;
