import { getTokenValue, Theme } from '@aily/ui-theme';
import { useTheme } from '@mui/material';

export function useTokenValue<T extends keyof Theme, K extends keyof Theme[T]>(
  type: T,
  key: K,
): Theme[T][K] {
  const { tokens } = useTheme();

  return getTokenValue(type, key, tokens);
}
