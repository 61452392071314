import { CircularProgress, useTheme } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import React from 'react';

import { BrainIcon, CheckIcon, IconWrapper } from '../../icons';
import { ButtonRoot } from '../Button/Button';
import { ButtonBaseProps } from '../ButtonBase';

export interface AddToBrainButtonProps extends ButtonBaseProps {
  status?: 'default' | 'loading' | 'added';
  sx?: SxProps<Theme>;
}

const AddToBrainButton: React.FC<AddToBrainButtonProps> = ({ status = 'default', ...rest }) => {
  const theme = useTheme();
  const added = status === 'added';
  const loading = status === 'loading';
  const disabled = added || loading;

  return (
    <ButtonRoot variant="secondary" disabled={disabled} {...rest}>
      <IconWrapper>
        {loading ? (
          <CircularProgress size={theme.tokens.sizing['icon.xsmall']} color="inherit" />
        ) : added ? (
          <CheckIcon size="icon.xsmall" />
        ) : (
          <BrainIcon size="icon.xsmall" />
        )}
      </IconWrapper>
      {added ? 'Added to brain' : 'Add to brain'}
    </ButtonRoot>
  );
};

export default AddToBrainButton;
